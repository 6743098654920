import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'dropdown',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent  {

 
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() options: string[] = [];
  // @Input() options: {label: string, value: any}[] = [];
  @Input() label: string;
  @Input() multiple: boolean = false;
  @Input() disabled: boolean = false;
  @Input() class: string = 'flex-auto  ml-2 mr-2 '

  records: any[] = []

  constructor(
  ) { 
    
  }




 

  
}
