import { Radar } from '@aman/types/radar.type';
import { API_URLS } from '@aman/utils/API_URLS';
import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FinanceService } from 'app/modules/admin/dashboards/finance/finance.service';
import { ApexOptions } from 'ng-apexcharts';
import { Subject, takeUntil } from 'rxjs';
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';
@Component({
    selector: 'radar-actions',
    templateUrl: './radar-actions.component.html',
    styleUrls: ['./radar-actions.component.scss']
})
export class RadarActionsComponent implements OnInit{
    @Input() radar: Radar;
    @ViewChild('recentTransactionsTable', { read: MatSort }) recentTransactionsTableMatSort: MatSort;

    data: any;
    accountBalanceOptions: ApexOptions;
    lastViolationsColumns: string[] = ['id', 'date', 'plateNumber', 'violationConfidence', 'status'];
    dataSource;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    radarHistory
    authorityToggle: boolean;
    recieveToggle: boolean;
    reviewToggle: boolean;

    /**
     * Constructor
     */
        constructor(private _financeService: FinanceService, private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.authorityToggle = this.radar.authorityEnabled;
        this.recieveToggle = this.radar.recieveEnabled;
        this.reviewToggle = this.radar.reviewEnabled;

        // Get the data
        // this._financeService.data$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((data) => {

        //         // Store the data
        //         this.data = data;

        //         // Store the table data
        //         this.recentTransactionsDataSource.data = data.recentTransactions;
        //         console.log(data.recentTransactions);


        //         // Prepare the chart data
        //         this._prepareChartData();
        //     });

            // console.log(this.radar);

        this._httpClient.get(API_URLS.violation.lastViolations.replace('{id}', this.radar.id).replace('{limit}', '5')).subscribe((res) => {
            // console.log("violations: ", res);
            this.dataSource = res
        })
        this._httpClient
        .get(API_URLS.Radar.getHistory.replace('{id}', this.radar.id))
        .subscribe((res) =>{
        //   console.log('radaaarHistory : ',res);
          this.radarHistory=res
    })

    }

    /**
     * After view init
     */
    // ngAfterViewInit(): void {
    //     // Make the data source sortable
    //     this.recentTransactionsDataSource.sort = this.recentTransactionsTableMatSort;
    // }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Prepare the chart data from the data
     *
     * @private
     */
    private _prepareChartData(): void {
        // Account balance
        this.accountBalanceOptions = {
            chart: {
                animations: {
                    speed: 400,
                    animateGradually: {
                        enabled: false
                    }
                },
                fontFamily: 'inherit',
                foreColor: 'inherit',
                width: '100%',
                height: '100%',
                type: 'area',
                sparkline: {
                    enabled: true
                }
            },
            colors: ['#A3BFFA', '#667EEA'],
            fill: {
                colors: ['#CED9FB', '#AECDFD'],
                opacity: 0.5,
                type: 'solid'
            },
            series: this.data.accountBalance.series,
            stroke: {
                curve: 'straight',
                width: 2
            },
            tooltip: {
                followCursor: true,
                theme: 'dark',
                x: {
                    format: 'MMM dd, yyyy'
                },
                y: {
                    formatter: (value): string => value + '%'
                }
            },
            xaxis: {
                type: 'datetime'
            }
        };
    }


    toggleConfig(event, config: 'authorityToggle' | 'recieveToggle' | 'reviewToggle'): void
    {
        console.log("event");

        this._httpClient.put(API_URLS.Radar[config].replace('{id}', this.radar.id), '').subscribe((res: boolean) => {
            return this[config] = res;
        })
    }

    downloadRadarHistory() {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.radarHistory.map(({ online:Online , date:Date})=>({Online, Date})));
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, 'Radar-history');
      } 

 saveAsExcelFile(buffer: any, fileName: string): void {
   let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
        const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
        });
        fs.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      }
    

}