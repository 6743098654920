import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';

@Injectable({
    providedIn: 'root',
})
export class RadarDrawerService {
    matDrawer: MatDrawer;
    constructor() {}

    toggleDrawer() {
        if (this.matDrawer) this.matDrawer.toggle();
    }
}
