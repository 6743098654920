import { OBaseEntity } from "../utils/o-base-entity.type";
import { SecurityProfile } from "./securityProfile.type";
import { Vendor } from "./vendor.type";


export enum HomeScreen {
    ADMIN = 'Admin',
    EMPLOYEE = 'Employee',
    VENDOR = 'Vendor'
}

export class User extends OBaseEntity {
    name: string;
    username: string;
    ssn: number;
    password: string;
    interface: string;
    role: [];
    isActive: boolean;
    otp: boolean;
    online: boolean;
    lastSeen: string;
    token?: string;
    status?: string;
    vendor?: Vendor
    securityProfile: SecurityProfile;
    homeScreen: HomeScreen
}
