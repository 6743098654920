import { U } from '@angular/cdk/keycodes';
import { environment } from 'environments/environment';

export const publicUrl = `http://${environment.urls.platform}:3000`;
export const uri = `http://${environment.urls.platform}:3000/v1`;
export const reportingUri = `http://${environment.urls.reporting}:8080/reporting-service/api`;
//export const uri = `https://68e2-197-36-169-69.eu.ngrok.io/v1`;
export const fileUri = `http://${environment.urls.images}:3000/v1`;
export const mapUri = `http://${environment.urls.map}:8080`;
export const imageServiceUri = `http://${environment.urls.images}:5001/image`;
export const socketUri = `http://${environment.urls.socket}:8063`;

export const API_URLS = {
    Auth: {
        login: uri + '/Auth/login',
        logout: uri + '/Auth/logout',
        loginByToken: uri + '/Auth/loginByToken',
        changePassword: uri + '/Auth/changepassword',
    },
    User: {
        post: uri + '/User',
        saveArray: uri + '/User/arr',
        getAll: uri + '/User',
        getAllAndCount: uri + '/User/allAndCount',
        getOne: uri + '/User/{id}',
        delete: uri + '/User/{id}',
        update: uri + '/User/{id}',
        lock: uri + '/User/lock/{id}',
        existsBy: uri + '/User/existsBy/{key}',
        resetPassword: uri + '/User/resetPassword/{id}',
        allReviewers: uri + '/User/findAllReviewers',
    },
    SecurityProfile: {
        post: uri + '/SecurityProfile',
        saveArray: uri + '/SecurityProfile/arr',
        getAll: uri + '/SecurityProfile',
        getAllAndCount: uri + '/SecurityProfile/allAndCount',
        getOne: uri + '/SecurityProfile/{id}',
        delete: uri + '/SecurityProfile/{id}',
        update: uri + '/SecurityProfile/{id}',
        lock: uri + '/SecurityProfile/lock/{id}',

        // resetPassword: uri + '/User/resetPassword/{id}',
    },
    Vendor: {
        post: uri + '/Vendor',
        saveArray: uri + '/Vendor/arr',
        getAll: uri + '/Vendor',
        getAllAndCount: uri + '/Vendor/allAndCount',
        getOne: uri + '/Vendor/{id}',
        delete: uri + '/Vendor/{id}',
        update: uri + '/Vendor/{id}',
        lock: uri + '/Vendor/lock/{id}',
    },
    Governorate: {
        post: uri + '/Governorate',
        saveArray: uri + '/Governorate/arr',
        getAll: uri + '/Governorate',
        getAllWithChilds: uri + '/Governorate/childs',
        getAllAndCount: uri + '/Governorate/allAndCount',
        getOne: uri + '/Governorate/{id}',
        delete: uri + '/Governorate/{id}',
        update: uri + '/Governorate/{id}',
        lock: uri + '/Governorate/lock/{id}',
        existsBy: uri + '/Governorate/existsBy/{key}',
    },
    LicenseType: {
        post: uri + '/LicenseType',
        saveArray: uri + '/LicenseType/arr',
        getAll: uri + '/LicenseType',
        getAllAndCount: uri + '/LicenseType/allAndCount',
        getOne: uri + '/LicenseType/{id}',
        delete: uri + '/LicenseType/{id}',
        update: uri + '/LicenseType/{id}',
        lock: uri + '/LicenseType/lock/{id}',
        existsBy: uri + '/LicenseType/existsBy/{key}',
    },
    ViolationTypes: {
        post: uri + '/ViolationType',
        saveArray: uri + '/ViolationType/arr',
        getAll: uri + '/ViolationType',
        getAllAndCount: uri + '/ViolationType/allAndCount',
        getOne: uri + '/ViolationType/{id}',
        delete: uri + '/ViolationType/{id}',
        update: uri + '/ViolationType/{id}',
        lock: uri + '/ViolationType/lock/{id}',
        existsBy: uri + '/ViolationType/existsBy/{key}',
    },
    Officer: {
        post: uri + '/Officer',
        saveArray: uri + '/Officer/arr',
        getAll: uri + '/Officer',
        getAllAndCount: uri + '/Officer/allAndCount',
        getOne: uri + '/Officer/{id}',
        delete: uri + '/Officer/{id}',
        update: uri + '/Officer/{id}',
        lock: uri + '/Officer/lock/{id}',
    },
    SkipReason: {
        post: uri + '/SkipReason',
        saveArray: uri + '/SkipReason/arr',
        getAll: uri + '/SkipReason',
        getAllAndCount: uri + '/SkipReason/allAndCount',
        getOne: uri + '/SkipReason/{id}',
        delete: uri + '/SkipReason/{id}',
        update: uri + '/SkipReason/{id}',
        lock: uri + '/SkipReason/lock/{id}',
    },
    Road: {
        post: uri + '/Road',
        upload: uri + '/Road/upload',
        saveArray: uri + '/Road/arr',
        getAll: uri + '/Road',
        getAllAndCount: uri + '/Road/allAndCount',
        getOne: uri + '/Road/{id}',
        delete: uri + '/Road/{id}',
        update: uri + '/Road/{id}',
        lock: uri + '/Road/lock/{id}',
        getRoadByGov: uri + '/Road/findByGovernorate',
        existsBy: uri + '/Road/existsBy/{key}',
    },
    Radar: {
        post: uri + '/Radar',
        upload: uri + '/Radar/upload',
        saveArray: uri + '/Radar/arr',
        getAll: uri + '/Radar/paginated',
        existsBy: uri + '/Radar/existsBy/{key}',

        getAllAndCount: uri + '/Radar/allAndCount',
        getOne: uri + '/Radar/{id}',
        delete: uri + '/Radar/{id}',
        update: uri + '/Radar/{id}',
        authorityToggle: uri + '/Radar/authorityToggle/{id}',
        recieveToggle: uri + '/Radar/recieveToggle/{id}',
        reviewToggle: uri + '/Radar/reviewToggle/{id}',
        getHistory: uri + '/Radar/history/findAllBy/{id}',
        allWithFilter: uri + '/Radar/allWithFilter',
        changeStatus: uri + '/Radar/changeStatus/{id}',
    },
    PortableRadar: {
        post: uri + '/PortableRadar',
        saveArray: uri + '/PortableRadar/arr',
        getAll: uri + '/PortableRadar',
        getAllAndCount: uri + '/PortableRadar/allAndCount',
        getOne: uri + '/PortableRadar/{id}',
        delete: uri + '/PortableRadar/{id}',
        update: uri + '/PortableRadar/{id}',
    },
    Settings: {
        post: uri + '/Settings',
        get: uri + '/Settings',
    },
    ViolationGroup: {
        getAll: uri + '/ViolationGroup/paginated',
        getOne: uri + '/ViolationGroup/{id}',
        ForReview: uri + '/ViolationGroup/ForReview/{id}',
        form125: uri + '/ViolationGroup/form125/{groupId}',
        delete: uri + '/ViolationGroup/{id}',

    },

    File: {
        upload: uri + '/File/upload',
        uploadMultiple: uri + '/File/uploadMultiple',
        download: fileUri + '/File/',
        view: fileUri + '/File/view?imgpath=',
    },
    Map: {
        platformMap: mapUri + '/styles/violationsplatform/{z}/{x}/{y}.png',
    },
    ReviewRoom: {
        post: uri + '/ReviewRoom',
        getAll: uri + '/ReviewRoom',
        getOne: uri + '/ReviewRoom/{id}',
        delete: uri + '/ReviewRoom/{id}',
        update: uri + '/ReviewRoom/{id}',
        lock: uri + '/ReviewRoom/lock/{id}',
    },

    CarColor: {
        post: uri + '/CarColor',
        getAll: uri + '/CarColor',
        getOne: uri + '/CarColor/{id}',
        delete: uri + '/CarColor/{id}',
        update: uri + '/CarColor/{id}',
        lock: uri + '/CarColor/lock/{id}',
        existsByCode: uri + '/CarColor/existsBy/{key}',
    },
    CarMake: {
        post: uri + '/CarMake',
        getAll: uri + '/CarMake',
        getOne: uri + '/CarMake/{id}',
        delete: uri + '/CarMake/{id}',
        update: uri + '/CarMake/{id}',
        lock: uri + '/CarMake/lock/{id}',
        existsByCode: uri + '/CarMake/existsBy/{key}',
    },
    CarModel: {
        post: uri + '/CarModel',
        getAll: uri + '/CarModel',
        getOne: uri + '/CarModel/{id}',
        delete: uri + '/CarModel/{id}',
        update: uri + '/CarModel/{id}',
        lock: uri + '/CarModel/lock/{id}',
        existsByCode: uri + '/CarModel/existsBy/{key}',
    },
    reviewScreen: {
        startReview: uri + '/Review/startReview',
        nextTask: uri + '/Review/NextTask',
        skipGroup: uri + '/Review/skipGroup/{id}',
        updatePlateNumber: uri + '/Review/updatePlateNumber/{id}',
        addViolation: uri + '/Review/addViolation/{groupId}/{violationTypeId}',
        deleteViolation: uri + '/Review/{violationId}',
        escalateViolation: uri + '/Review/escalate/{groupId}',
        refetchLicensingData: uri + '/Review/refetchLicensingData/{groupId}',
        sendToAuthority: uri + '/Review/sendToAuthority/{groupId}',
        employeeQueue: uri + '/Review/previewQueue/{type}',
        updateImageManually:
            uri + '/ViolationGroup/saveImageManually/{groupId}',
        ocrCheck: uri + '/ViolationGroup/OcrCheck/{groupId}',
    },
    reportCategory: {
        post: uri + '/ReportCategory',
        getAll: uri + '/ReportCategory',
        getOne: uri + '/ReportCategory/{id}',
        delete: uri + '/ReportCategory/{id}',
        update: uri + '/ReportCategory/{id}',
        getPaginated: uri + '/ReportCategory/Paginated',
        lock: uri + '/ReportCategory/lock/{id}',

        // audit: uri + '/ReportCategory/audit/{id}',
        // auditVersion: uri + '/ReportCategory/audit/{id}/{rev}',
        // deleteAudits: uri + '/ReportCategory/deleteAudits',
    },
    reportDefinition: {
        post: uri + '/ReportDefinition',
        getAll: uri + '/ReportDefinition',
        getOne: uri + '/ReportDefinition/{id}',
        byCagtegory: uri + '/ReportDefinition/byCategory/{categoryId}',
        runReport: uri + '/ReportDefinition/run/{id}',
        exportReport: reportingUri + '/ReportDefinition/export',
        exists: uri + '/ReportDefinition/existsBy/{key}',
        // getReport: uri + '/ReportDefinition/getReport/{id}',
        delete: uri + '/ReportDefinition/{id}',
        update: uri + '/ReportDefinition/{id}',
        lock: uri + '/ReportDefinition/lock/{id}',

        // getPaginated: uri + '/ReportCategory/getPaginated',
        // audit: uri + '/ReportCategory/audit/{id}',
        // auditVersion: uri + '/ReportCategory/audit/{id}/{rev}',
        // deleteAudits: uri + '/ReportCategory/deleteAudits',
    },
    statistics: {
        getAll: uri + '/Statistics/adminBoard',
        getRadarStatistics: uri + '/Statistics/radarChart/{id}',
        reviewerDashboard: uri + '/Statistics/reviewerDashboard',
        radarByStatus: uri + '/Statistics/groupViolationsByStatus',
    },
    violation: {
        lastViolations: uri + '/Violation/lastViolations/{id}/{limit}',
    },
    SpeedMapException: {
        post: uri + '/SpeedMapException',
        getAll: uri + '/SpeedMapException',
        getAllAndCount: uri + '/SpeedMapException/allAndCount',
        getOne: uri + '/SpeedMapException/{id}',
        delete: uri + '/SpeedMapException/{id}',
        update: uri + '/SpeedMapException/{id}',
        lock: uri + '/SpeedMapException/lock/{id}',
        existsBy: uri + '/SpeedMapException/existsBy/{key}',
    },
    ProsecutionQueue: {
        getAll: uri + '/Prosecution/previewQueue',
        // getAll: uri + '/Review/previewQueue/{reviewRoomId}/{userId}',
        toggleQueue: uri + '/Prosecution/toggleQueue',
        queueStatus: uri + '/Prosecution/queueStatus',
    },
    Search: {
        get: uri + '/search/globalSearch/{word}',
    },
    Notification: {
        get: uri + '/notification/forUser/{id}',
        post: uri + '/notification',
        update: uri + '/notification/read/{id}',
    },
    TrackingList: {
        post: uri + '/TrackingList',
        getAll: uri + '/TrackingList',
        getOne: uri + '/TrackingList/{id}',
        delete: uri + '/TrackingList/{id}',
        update: uri + '/TrackingList/{id}',
        lock: uri + '/TrackingList/lock/{id}',
        existsByCode: uri + '/TrackingList/existsBy/{key}',
    },
    TreeCategory: {
        post: uri + '/TreeCategory',
        getAll: uri + '/TreeCategory',
        getAllAndCount: uri + '/TreeCategory/allAndCount',
        getOne: uri + '/TreeCategory/{id}',
        delete: uri + '/TreeCategory/{id}',
        update: uri + '/TreeCategory/{id}',
        lock: uri + '/TreeCategory/lock/{id}',
        existsBy: uri + '/TreeCategory/existsBy/{key}',
        getTree: uri + '/TreeCategory/getTree',
    },
    VehicleType: {
        post: uri + '/VehicleType',
        getAll: uri + '/VehicleType',
        getOne: uri + '/VehicleType/{id}',
        delete: uri + '/VehicleType/{id}',
        update: uri + '/VehicleType/{id}',
        lock: uri + '/VehicleType/lock/{id}',
        existsByCode: uri + '/VehicleType/existsBy/{key}',
    },
    Payment: {
        post: uri + '/Payment',
        getAll: uri + '/Payment',
        getAllPaginated: uri + '/Payment/paginated',
        findOne: uri + '/Payment/{id}',
        findByKey: uri + '/Payment/findBy/{key}',
        existsByCode: uri + '/Payment/existsBy/{key}',
        delete: uri + '/Payment/{id}',
        update: uri + '/Payment/{id}',
        lock: uri + '/Payment/lock/{id}',
    }
};
