import { Component, ElementRef, EventEmitter, HostBinding, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { debounceTime, filter, map, Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from '@fuse/animations/public-api';
import { API_URLS } from '@aman/utils/API_URLS';

@Component({
    selector: 'search',
    templateUrl: './search.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'fuseSearch',
    animations: fuseAnimations
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce: number = 300;
    @Input() minLength: number = 2;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    opened: boolean = false;
    resultSets: {
        entityType: string,
        id: number,
        name: string,
        link: string
    }[];
    searchControl: UntypedFormControl = new UntypedFormControl();
    private _matAutocomplete: MatAutocomplete;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _elementRef: ElementRef,
        private _httpClient: HttpClient,
        private _renderer2: Renderer2
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Host binding for component classes
     */
    @HostBinding('class') get classList(): any {
        return {
            'search-appearance-bar': this.appearance === 'bar',
            'search-appearance-basic': this.appearance === 'basic',
            'search-opened': this.opened
        };
    }

    /**
     * Setter for bar search input
     *
     * @param value
     */
    @ViewChild('barSearchInput')
    set barSearchInput(value: ElementRef) {
        // If the value exists, it means that the search input
        // is now in the DOM, and we can focus on the input..
        if (value) {
            // Give Angular time to complete the change detection cycle
            setTimeout(() => {

                // Focus to the input element
                value.nativeElement.focus();
            });
        }
    }

    /**
     * Setter for mat-autocomplete element reference
     *
     * @param value
     */
    @ViewChild('matAutocomplete')
    set matAutocomplete(value: MatAutocomplete) {
        this._matAutocomplete = value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On changes
     *
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges): void {
        // Appearance
        if ('appearance' in changes) {
            // To prevent any issues, close the
            // search after changing the appearance
            this.close();
        }
    }

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the search field value changes
        this.searchControl.valueChanges
            .pipe(
                debounceTime(this.debounce),
                takeUntil(this._unsubscribeAll),
                map((value) => {

                    // Set the resultSets to null if there is no value or
                    // the length of the value is smaller than the minLength
                    // so the autocomplete panel can be closed
                    if (!value || value.length < this.minLength) {
                        this.resultSets = null;
                    }

                    // Continue
                    return value;
                }),
                // Filter out undefined/null/false statements and also
                // filter out the values that are smaller than minLength
                filter(value => value && value.length >= this.minLength)
            )
            .subscribe((value) => {
                return this._httpClient.get(API_URLS.Search.get.replace('{word}', value))
                    //return this._httpClient.post('api/common/search', {query: value})
                    .subscribe((resultSets: {
                        entityType: string,
                        id: number,
                        name: string,
                        link: string

                    }[]) => {
                        console.log('resss:', resultSets);

                        // Store the result sets
                      let  resultSet = resultSets.map((r) => {
                            let obj = {
                                ...r
                            }
                            switch (r.entityType?.toLowerCase()) {
                                case 'user':
                                    {
                                        obj['link'] = `/user-management/user/edit/${r.id}`
                                        break;
                                    }
                                case 'securityprofile':
                                    {
                                        obj['link'] = `/user-management/security-profile/edit/${r.id}`
                                        break;
                                    }
                                case 'carcolor':
                                    {
                                        obj['link'] = `/basic-data/car-color/edit/${r.id}`
                                        break;
                                    }
                                case 'carmake':
                                    {
                                        obj['link'] = `/basic-data/car-make/edit/${r.id}`
                                        break;
                                    }
                                case 'carmodel':
                                    {
                                        obj['link'] = `/basic-data/car-model/edit/${r.id}`
                                        break;
                                    }
                                case 'governorate':
                                    {
                                        obj['link'] = `/basic-data/governorate/edit/${r.id}`

                                        break;
                                    }
                                case 'licensetype':
                                    {
                                        obj['link'] = `/basic-data/license-type/edit/${r.id}`
                                        break;
                                    }
                                case 'officer':
                                    {
                                        obj['link'] = `/basic-data/officer/edit/${r.id}`
                                        break;
                                    }
                                case 'road':
                                    {
                                        obj['link'] = `/basic-data/road/edit/${r.id}`
                                        break;
                                    }
                                case 'reviewroom':
                                    {
                                        obj['link'] = `/basic-data/reviewer-room/edit/${r.id}`
                                        break;
                                    }
                                case 'skipreason':
                                    {
                                        obj['link'] = `/violation-definition/skip-reason/edit/${r.id}`
                                        break;
                                    }
                                case 'speedmapexception':
                                    {
                                        obj['link'] = `/violation-definition/speedMap-exception/edit/${r.id}`
                                        break;
                                    }
                                case 'vendor':
                                    {
                                        obj['link'] = `/violation-definition/vendor/edit/${r.id}`
                                        break;
                                    }
                                case 'violationtype':
                                    {
                                        obj['link'] = `/violation-definition/violation-type/edit/${r.id}`
                                        break;
                                    }
                                case 'radar':
                                    {
                                        obj['link'] = `/violation-sources/radar/edit/${r.id}`
                                        break;
                                    }
                                case 'violation':
                                    {
                                        obj['link'] = `/violation/violation-details/${r.id}`;
                                        break;
                                    }

                                default:
                                    { 
                                        console.log('test',obj);
                                        
                                        break; }
                            }

                            return obj;
                        });
                        this.resultSets = resultSet
                        console.log('res with link:', resultSet);
                        // Execute the event
                        this.search.next(resultSet);
                    });

                // return this._httpClient.post('api/common/search', {query: value})
                // .subscribe((res:any)=>{
                //     console.log('resss:', res);

                // //         // Store the result sets
                //          this.resultSets = res

                //          //Execute the event
                //                  this.search.next(res);

                // })

            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void {
        // Escape
        if (event.code === 'Escape') {
            // If the appearance is 'bar' and the mat-autocomplete is not open, close the search
            if (this.appearance === 'bar' && !this._matAutocomplete.isOpen) {
                this.close();
            }
        }
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    open(): void {
        // Return if it's already opened
        if (this.opened) {
            return;
        }

        // Open the search
        this.opened = true;
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void {
        // Return if it's already closed
        if (!this.opened) {
            return;
        }

        // Clear the search input
        this.searchControl.setValue('');

        // Close the search
        this.opened = false;
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
