import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, map, switchMap, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';
import { UserService } from '../user/user.service';

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private _navigation: ReplaySubject<Navigation> =
        new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private userService: UserService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation> {
        return this.userService.user$.pipe(
            switchMap((user) => {
                return this._navigation.pipe(
                    map((nav) => {
                        if (!user.securityProfile) return nav;
                        else {
                            if (user.securityProfile) {
                                let permissions = user.securityProfile.lines
                                    ?.filter((permisiion) => permisiion.canRead)
                                    .map((permission) => permission.entityType);
                                console.log(permissions, 'permissions');

                                let companct = nav.compact;
                                let compactDAta = [];
                                companct.forEach((item) => {
                                    if (item.children) {
                                        item.children = item.children.filter(
                                            (nav) => {
                                                let exists = permissions.find(
                                                    (per) =>
                                                        nav.id
                                                            .toLowerCase()
                                                            .endsWith(
                                                                per.toLowerCase()
                                                            )
                                                );
                                                return (
                                                    exists !== null &&
                                                    exists !== undefined
                                                );
                                            }
                                        );
                                        if (item.children?.length > 0) {
                                            compactDAta.push(item);
                                        }
                                    } else {
                                        for (
                                            let i = 0;
                                            i < permissions.length;
                                            i++
                                        ) {
                                            if (
                                                permissions[i].toLowerCase() ==
                                                item.id.toLowerCase()
                                            ) {
                                                compactDAta.push(item);
                                                break;
                                            }
                                        }

                                        // let exists = permissions.find((per) =>
                                        //     item.id
                                        //         .toLowerCase()
                                        //         .endsWith(per.toLowerCase())
                                        // );

                                        // if (exists) {
                                        //     compactDAta.push(item);
                                        // }
                                    }

                                    console.log('compact dataa: ', compactDAta);
                                });

                                let defaultt = nav.default;
                                let data = [];
                                defaultt.forEach((item) => {
                                    if (item.children) {
                                        item.children = item.children.filter(
                                            (nav) => {
                                                let exists = permissions.find(
                                                    (per) =>
                                                        nav.id
                                                            .toLowerCase()
                                                            .endsWith(
                                                                per.toLowerCase()
                                                            )
                                                );
                                                return (
                                                    exists !== null &&
                                                    exists !== undefined
                                                );
                                            }
                                        );
                                        if (item.children?.length > 0) {
                                            data.push(item);
                                        }
                                    } else {
                                        for (
                                            let i = 0;
                                            i < permissions.length;
                                            i++
                                        ) {
                                            if (
                                                permissions[i].toLowerCase() ==
                                                item.id.toLowerCase()
                                            ) {
                                                data.push(item);
                                                break;
                                            }
                                        }
                                    }

                                    // item.children = item.children?.filter(
                                    //     (nav) => {
                                    //         let exists = permissions.find(
                                    //             (per) =>
                                    //                 nav.id
                                    //                     .toLowerCase()
                                    //                     .endsWith(
                                    //                         per.toLowerCase()
                                    //                     )
                                    //         );
                                    //         return (
                                    //             exists !== null &&
                                    //             exists !== undefined
                                    //         );
                                    //     }
                                    // );
                                    // if (item.children?.length > 0) {
                                    //     data.push(item);
                                    // }
                                });
                                console.log(defaultt, 'defaultt', data);

                                let navigate: Navigation = {
                                    compact: data,
                                    default: data,
                                    horizontal: data,
                                    futuristic: data,
                                };
                                console.log(data, navigate, '999');

                                return navigate;
                            }
                        }
                    })
                );
            })
        );
        // return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get(): Observable<Navigation> {
        return this._httpClient.get<Navigation>('api/common/navigation').pipe(
            tap((navigation) => {
                this._navigation.next(navigation);
            })
        );
    }
}
