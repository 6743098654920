import { Component } from '@angular/core';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent
{
    /**
     * Constructor
     */
    constructor()
    {
        document.addEventListener('DOMContentLoaded', function() {
            if (!Notification) {
             alert('Desktop notifications not available in your browser. Try Chromium.');
             return;
            }

            if (Notification.permission !== 'granted')
             Notification.requestPermission();
           });

    }
}
