
<div class="grid grid-cols-1 xl:grid-cols-3 gap-8 w-full mt-8 ">
    <!-- Recent transactions table -->
    <div class="xl:col-span-2 flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden">
        <div class="p-6">
            <div class="text-lg font-medium tracking-tight leading-6 truncate">{{'Recent Violations' |transloco}}
            </div>
            <!-- <div    class="text-secondary font-medium">{{'pending' |transloco}} {}, {{'sent' |transloco}} 4</div> -->
        </div>
        <div class="overflow-x-auto mx-6">
            <table class="w-full bg-transparent" mat-table matSort [dataSource]="dataSource"
                [trackBy]="trackByFn" #recentTransactionsTable>

                <!-- Transaction ID -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        {{'Violation Code' |transloco}}

                    </th>
                    <td mat-cell *matCellDef="let transaction">
                        <span class="pr-6 font-medium text-sm text-secondary whitespace-nowrap">
                            {{transaction.id}}
                        </span>
                    </td>
                </ng-container>

                <!-- Date -->
                <ng-container matColumnDef="date">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        {{'Time' |transloco}}
                    </th>
                    <td mat-cell *matCellDef="let transaction">
                        <span class="pr-6 whitespace-nowrap">
                            {{transaction.group.timestamp | date:'MMM dd, y h:m a':"UTC+00:00"}}
                        </span>
                    </td>
                </ng-container>

                <!-- Name -->
                <ng-container matColumnDef="plateNumber">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        {{'Plate Number' |transloco}}
                    </th>
                    <td mat-cell *matCellDef="let transaction">
                        <span class="pr-6 whitespace-nowrap">
                            {{transaction.group.plateNumber}}
                        </span>
                    </td>
                </ng-container>

                <!-- Amount -->
                <ng-container matColumnDef="violationConfidence">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        {{'Confidence Level' |transloco}}
                    </th>
                    <td mat-cell *matCellDef="let transaction">
                        <span class="pr-6 font-medium whitespace-nowrap">
                            {{transaction.violationConfidence }}
                        </span>
                    </td>
                </ng-container>

                <!-- Status -->
                <ng-container matColumnDef="status">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef>
                        {{'Status' |transloco}}
                    </th>
                    <td mat-cell *matCellDef="let transaction">
                        <span
                            class="inline-flex items-center font-bold text-xs px-2.5 py-0.5 rounded-full tracking-wide uppercase"
                            [ngClass]="{'bg-red-200 text-red-800 dark:bg-red-600 dark:text-red-50': transaction.status === 'PENDING',
                                        'bg-green-200 text-green-800 dark:bg-green-600 dark:text-green-50': transaction.status === 'completed'}">
                            <span class="leading-relaxed whitespace-nowrap">{{transaction.status |transloco}}</span>
                        </span>
                    </td>
                </ng-container>

                <!-- Footer -->
                <ng-container matColumnDef="recentOrdersTableFooter">
                    <td class="py-6 px-0 border-0" mat-footer-cell *matFooterCellDef colspan="6">
                        <button mat-stroked-button>{{'See all violations' |transloco}}</button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="lastViolationsColumns"></tr>
                <tr class="order-row h-16" mat-row *matRowDef="let row; columns: lastViolationsColumns;"></tr>
                <!-- <tr class="h-16 border-0" mat-footer-row *matFooterRowDef="['recentOrdersTableFooter']"></tr> -->
            </table>
        </div>
    </div>

    <!-- Budget -->
    <div class="flex flex-col flex-auto p-6 bg-card rounded-2xl shadow">
        <div class="flex items-center">
            <div class="flex flex-col">
                <div class="text-lg font-medium tracking-tight leading-6 truncate">{{'Radar Control' |transloco}}
                </div>
                <div class="text-secondary font-medium">{{'Be carefull' |transloco}}</div>
            </div>

        </div>

        <div class="my-8 space-y-8">
            <div class="flex flex-col">
                <div class="flex items-center cursor-pointer" (click)="toggleConfig($event, 'recieveToggle')">
                    <div
                        class="flex items-center justify-center w-14 h-14 rounded bg-red-100 text-red-800 dark:bg-red-600 dark:text-red-50">
                        <mat-icon class="text-current" [svgIcon]="'mat_outline:power_settings_new'"></mat-icon>
                    </div>
                    <div class="flex-auto mx-4 leading-none "  >
                        <div class="flex-auto cursor-pointer">
                            <div class="leading-6 font-medium">{{'Receive Violations' |transloco}}</div>
                            <mat-slide-toggle
                            class="mx-4"
                            [color]="'primary'"
                            [(ngModel)]="recieveToggle">
                        </mat-slide-toggle>
                        </div>

                    </div>

                </div>
            </div>

            <div class="flex flex-col">
                <div class="flex items-center cursor-pointer" (click)="toggleConfig($event, 'reviewToggle')">
                    <div
                        class="flex items-center justify-center w-14 h-14 rounded bg-blue-100 text-blue-800 dark:bg-blue-600 dark:text-blue-50">
                        <mat-icon class="text-current" [svgIcon]="'mat_outline:remove_red_eye'"></mat-icon>
                    </div>
                    <div class="flex-auto mx-4 leading-none ">
                        <div class="flex-auto cursor-pointer">
                            <div class="leading-6 font-medium">{{'Review' |transloco}}</div>
                            <mat-slide-toggle
                            class="mx-4"
                            [color]="'blue'"
                            [(ngModel)]="reviewToggle">
                        </mat-slide-toggle>
                        </div>

                    </div>

                </div>
            </div>

            <div class="flex flex-col">
                <div class="flex items-center cursor-pointer" (click)="toggleConfig($event, 'authorityToggle')">
                    <div
                        class="flex items-center justify-center w-14 h-14 rounded bg-purple-100 text-purple-800 dark:bg-purple-600 dark:text-purple-50">
                        <mat-icon class="text-current" [svgIcon]="'mat_outline:send'"></mat-icon>
                    </div>
                    <div class="flex-auto mx-4 leading-none "  >
                        <div class="flex-auto cursor-pointer">
                            <div class="leading-6 font-medium">{{'Send To Authority' |transloco}}</div>
                            <mat-slide-toggle
                            class="mx-4"
                            [color]="'accent'"
                            [(ngModel)]="authorityToggle">
                        </mat-slide-toggle>
                        </div>

                    </div>

                </div>
            </div>

        </div>
        <div class="flex items-center mt-auto">
            <button class="mt-2" mat-stroked-button (click)="downloadRadarHistory()" [disabled]="radarHistory?.length === 0">
                {{'Download Change History' |transloco}}
            </button>
        </div>
    </div>
</div>
