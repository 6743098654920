import { API_URLS } from '@aman/utils/API_URLS';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
    selector: 'app-switch-button',
    template: `
        <!-- <p>
      switch-button works!
    </p> -->
        <mat-slide-toggle
            [(ngModel)]="isActive"
            color="warn"
            (change)="update()"
        >
        </mat-slide-toggle>
        <!-- <nz-switch nzSize="small" [(ngModel)]="isActive"></nz-switch>  -->
    `,
    styles: [],
})
export class SwitchButtonComponent implements ICellRendererAngularComp {
    isActive: boolean;
    params: any;
    constructor(
        private _httpClient: HttpClient
    ) {}
    agInit(params: ICellRendererParams): void {
        this.params = params
        this.isActive = params.value;   
    }

    refresh(params?: ICellRendererParams): boolean {
        return true;
    }
    update() {
        // console.log('isActive',this.isActive);
        if(this.params?.colDef?.field ==='recieveEnabled'){
            this._httpClient.put(API_URLS.Radar.recieveToggle.replace('{id}', this.params.data.id),'').subscribe((res) => {
            })

        }
        else if(this.params?.colDef?.field ==='reviewEnabled'){
            this._httpClient.put(API_URLS.Radar.reviewToggle.replace('{id}', this.params.data.id),'').subscribe((res) => {
            })
        }
        else if(this.params?.colDef?.field ==='authorityEnabled'){
            this._httpClient.put(API_URLS.Radar.authorityToggle.replace('{id}', this.params.data.id),'').subscribe((res) => {
            })

        }
        else if(this.params?.colDef?.field ==="isActive"){
            let data={
                homeScreen:this.params.data.homeScreen,
                   isActive :this.isActive,
                   name: this.params.data.name,
                   ssn: this.params.data.ssn,
                   username: this.params.data.username,
                   id: this.params.data.id
                   }
                //    console.log('isActive',this.isActive);
                //    console.log('params',this.params);
                   
                   
           
                   this._httpClient.put(API_URLS.User.update.replace('{id}', this.params.data.id),data).subscribe((res) => {
                       console.log("res: ", res);
                   })
        }

        
    }
}
