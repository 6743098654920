export enum PageType {
    allData = 'allData',
    sectionDashboard = 'sectionDashboard'
}

export class BreadcrumbSectionData {
    title: string;
    link: string
}

export class AllRecordsPageData {
    title: string;
    type: PageType;
    add: boolean;
    dataCount?: number;
    integrateWithLicensingSystem: boolean;
    frontEndRouteModuleRoute: string;
    section?: BreadcrumbSectionData;

    onExport?: () => void;
    onFilter?: (value: string) => void;
}
