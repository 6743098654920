import { imageServiceUri } from './../../../@aman/utils/API_URLS';
import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/
@Pipe({name: 'imageService'})
export class ImageServicePipe implements PipeTransform {
  transform(path: string): string {
    return `${imageServiceUri}${path}`
  }
}
