import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, takeUntil } from 'rxjs';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { OverrideService } from '@aman/utils/override.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_URLS } from '@aman/utils/API_URLS';
import { UserService } from 'app/core/user/user.service';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications',
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel')
    private _notificationsPanel: TemplateRef<any>;
    @HostListener('scroll', ['$event']) private;
    notifications: Notification[];
    unreadCount: number = 0;
    page: number = 1;
    pageSize: number;
    meta;
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    userId: string;
    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        public override: OverrideService,
        private _httpClient: HttpClient,
        private _userService: UserService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                // console.log('ussseeer: ',data)
                this.userId = data?.id;
            });
        // Subscribe to notification changes
        //this._notificationsService.notifications$
        this._httpClient
            .get(API_URLS.Notification.get.replace('{id}', this.userId))
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res: Notification[]) => {
                // Load the notifications
                // this.notifications =Array.from({ length: 10}, () =>(res['items'])).flat()
                this.notifications = res['items'];
                this.meta = res['meta'];

                // Calculate the unread count
                this._calculateUnreadCount();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Dispose the overlay
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(
            new TemplatePortal(this._notificationsPanel, this._viewContainerRef)
        );
    }

    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): void {
        // Mark all as read
        this._notificationsService.markAllAsRead().subscribe();
    }

    /**
     * Toggle read status of the given notification
     */
    toggleRead(notification): void {
        // Toggle the read status
        //  notification.seen = !notification.seen;
        //  const updatedNotification = {...notification}
        // Update the notification
        //  this._notificationsService.update(notification.id, notification).subscribe();
        this._httpClient
            .put(
                API_URLS.Notification.update.replace('{id}', notification?.id),
                {}
            )
            .subscribe((res) => {
                console.log('updated notification', res);
                // notification = res
                // notification.seen=res['seen']
            });
    }
    // scroll
    onScroll($event: any): void {
        // console.log( 'nnnnnnnn', $event.srcElement.scrollTop);
        if (
            $event.target.offsetHeight + $event.target.scrollTop >=
            $event.target.scrollHeight
        ) {
            console.log('End');
            let parameters = {
                page: this.meta.currentPage + 1,
                pageSize: this.meta.itemsPerPage,
            };
            let queryParams = new HttpParams({ fromObject: parameters });
            this._httpClient
                .get(API_URLS.Notification.get.replace('{id}', this.userId), {
                    params: queryParams,
                })
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((res: Notification[]) => {
                    // this.notifications=Array.from({ length: 2}, () =>([...this.notifications,...res['items']])).flat()
                    this.notifications = [
                        ...this.notifications,
                        ...res['items'],
                    ];
                    this.meta = res['meta'];
                });
        }
    }

    /**
     * Delete the given notification
     */
    delete(notification: Notification): void {
        // Delete the notification
        // this._notificationsService.delete(notification.id).subscribe();
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay
                .position()
                .flexibleConnectedTo(
                    this._notificationsOrigin._elementRef.nativeElement
                )
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                    },
                ]),
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }

    /**
     * Calculate the unread count
     *
     * @private
     */
    private _calculateUnreadCount(): void {
        let count = 0;

        if (this.notifications && this.notifications.length) {
            count = this.notifications.filter(
                (notification) => !notification['seen']
            ).length;
        }

        this.unreadCount = count;
    }
}
