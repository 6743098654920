<div class="flex flex-col flex-auto min-w-0 w-full test">

    <!-- Header -->
    <div
        class="w-full flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">
        <div class="flex-1 min-w-0">
            <!-- Breadcrumbs -->
            <div class="flex flex-wrap items-center font-medium">
                <div>
                    <a class="whitespace-nowrap text-primary-500" routerLink="./">{{"Centeralized Platform" |
                        transloco}}</a>
                </div>
                <div class="flex items-center ml-1 whitespace-nowrap" *ngIf="controlView.section">
                    <mat-icon class="icon-size-5 text-secondary" class="origin-center rtl:scale-x-[-1]"
                        [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                    <a class="ml-1 text-primary-500" [routerLink]="controlView.section.link">{{controlView.section.title
                        | transloco}}</a>
                </div>
                <div class="flex items-center ml-1 whitespace-nowrap">
                    <mat-icon class="icon-size-5 text-secondary origin-center rtl:scale-x-[-1]"
                        [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                    <a class="ml-1 text-primary-500" [routerLink]="[controlView.redirectRoute]">{{controlView.title | transloco}}</a>
                </div>
                <div class="flex items-center ml-1 whitespace-nowrap">
                    <mat-icon class="icon-size-5 text-secondary origin-center rtl:scale-x-[-1]"
                        [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                    <a class="ml-1 text-primary-500">{{controlView.subTitle | transloco}}</a>
                </div>
            </div>

            <div class="flex flex-row justify-between">
                <!-- Title -->
                <div class="mt-2">
                    <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                        {{controlView.subTitle | transloco}}
                    </h2>
                </div>
                <!-- Actions -->
                <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4" *ngIf="controlView.isEdit">
                    <mat-slide-toggle [color]="'warn'" (change)="toggleForm(controlView.editId)"
                        *ngIf="!controlView.form.disabled ? (userService.can('lock',module) |async)  : (userService.can('Unlock',module) | async) "
                        [checked]="controlView.form.disabled">
                        <span class="mx-2" *ngIf="!controlView.form.disabled">{{"Lock" | transloco}}</span>
                        <span class="mx-2" *ngIf="controlView.form.disabled">{{"Lock" | transloco}}</span>
                    </mat-slide-toggle>
                    <!--
                    <button class="ml-4 min-w-fit" mat-flat-button [color]="'accent'"
                        (click)="toggleForm(controlView.editId)">

                        <mat-icon [svgIcon]="'mat_solid:lock'" *ngIf="!controlView.form.disabled"></mat-icon>
                        <mat-icon [svgIcon]="'mat_solid:lock_open'" *ngIf="controlView.form.disabled"></mat-icon>
                        <span class="ml-2 mr-1" *ngIf="!controlView.form.disabled">{{"Lock Form" | transloco}}</span>
                        <span class="ml-2 mr-1" *ngIf="controlView.form.disabled">{{"Unlock Form" | transloco}}</span>
                    </button> -->
                </div>
            </div>
        </div>
    </div>

    <div class="flex-auto p-6 sm:p-10">

        <div class="w-full">
            <form class="flex flex-col mt-4 px-8 pt-10  bg-card rounded-2xl overflow-hidden" form
                [formGroup]="controlView.form" >

                <ng-content></ng-content>

                <div class="flex items-center justify-end border-t -mx-8 mt-8 px-8 py-5 bg-gray-50 dark:bg-gray-700"
                *ngIf="controlView.footer">
                    <button nz-button nzType="text" nzShape="round"
                        [routerLink]="controlView.redirectRoute">{{"Cancel" | transloco}}</button>

                    <button class="px-6 ml-3" mat-flat-button [color]="'primary'" (click)="submit()"
                        [disabled]="!controlView.form.valid"
                        *ngIf="!controlView.isEdit ? (userService.can('create',module) |async) :  (userService.can('update',module) | async) ">
                        <mat-icon class="icon-size-6 mr-1" matPrefix [svgIcon]="'mat_solid:save'"></mat-icon>
                        {{controlView.isEdit ? ("Update"| transloco) : ('Add'|transloco)}}
                    </button>
                </div>

            </form>
        </div>
    </div>
</div>
