import { Radar } from '@aman/types/radar.type';
import { API_URLS } from '@aman/utils/API_URLS';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { AnalyticsService } from 'app/modules/admin/dashboards/analytics/analytics.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'radar-violation-count-chart',
    templateUrl: './radar-violation-count-chart.component.html',
    styleUrls: ['./radar-violation-count-chart.component.scss']
})
export class RadarViolationCountChartComponent implements OnInit {
    @Input() radar: Radar;
    data: any;
    chartViolations: { chart: { animations: { speed: number; animateGradually: { enabled: boolean; }; }; fontFamily: string; foreColor: string; width: string; height: string; type: string; toolbar: { show: boolean; }; zoom: { enabled: boolean; }; }; colors: string[]; dataLabels: { enabled: boolean; }; fill: { colors: string[]; }; grid: { show: boolean; borderColor: string; padding: { top: number; bottom: number; left: number; right: number; }; position: string; xaxis: { lines: { show: boolean; }; }; }; series: any; stroke: { width: number; }; tooltip: { followCursor: boolean; theme: string; x: { format: string; }; y: { formatter: (value: number) => string; }; }; xaxis: { axisBorder: { show: boolean; }; axisTicks: { show: boolean; }; crosshairs: { stroke: { color: string; dashArray: number; width: number; }; }; labels: { offsetY: number; style: { colors: string; }; }; tickAmount: number; tooltip: { enabled: boolean; }; type: string; }; yaxis: { axisTicks: { show: boolean; }; axisBorder: { show: boolean; }; min: (min: any) => number; max: (max: any) => number; tickAmount: number; show: boolean; }; };
    id:any
    lastMonth;
    thisMonth;
    series
    constructor(
        private _analyticsService: AnalyticsService,
        private _router: Router,
        private transloco: TranslocoService,
        private _httpClient: HttpClient, 
        private route: ActivatedRoute,
    ) { }
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
        * On destroy
        */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id');
        // Get the data
        this._analyticsService.data$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {

                // Store the data
                this.data = data;
                // console.log( 'data',this.data);


                // Prepare the chart data
                this._prepareChartData();
            });

        // Attach SVG fill fixer to all ApexCharts
        window['Apex'] = {
            chart: {
                events: {
                    mounted: (chart: any, options?: any): void => {
                        this._fixSvgFill(chart.el);
                    },
                    updated: (chart: any, options?: any): void => {
                        this._fixSvgFill(chart.el);
                    }
                }
            }
        };
        // get data
        this.getAllstatistics()

    }

    private _prepareChartData(): void {
        // Visitors
        this.chartViolations = {
            chart: {
                animations: {
                    speed: 400,
                    animateGradually: {
                        enabled: false
                    }
                },
                fontFamily: 'inherit',
                foreColor: 'inherit',
                width: '100%',
                height: '100%',
                type: 'area',
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: true
                }
            },
            colors: ['#818CF8'],
            dataLabels: {
                enabled: false
            },
            fill: {
                colors: ['#312E81']
            },
            grid: {
                show: true,
                borderColor: '#334155',
                padding: {
                    top: 10,
                    bottom: -40,
                    left: 0,
                    right: 0
                },
                position: 'back',
                xaxis: {
                    lines: {
                        show: true
                    }
                }
            },
            series: this.series,
            stroke: {
                width: 2
            },
            tooltip: {
                followCursor: true,
                theme: 'dark',
                x: {
                    format: 'MMM dd, yyyy'
                },
                y: {
                    formatter: (value: number): string => `${value}`
                }
            },
            xaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    stroke: {
                        color: '#475569',
                        dashArray: 0,
                        width: 2
                    }
                },
                labels: {
                    offsetY: -20,
                    style: {
                        colors: '#CBD5E1'
                    }
                },
                tickAmount: 25,
                tooltip: {
                    enabled: false
                },
                type: 'datetime'
            },
            yaxis: {
                axisTicks: {
                    show: false
                },
                axisBorder: {
                    show: false
                },
                 min: (min): number => min - 750,
                 max: (max): number => max + 250,
                // formatter: (value: number): string => value.toFixed(0),
                tickAmount: 5,
                show: false
            }
        };
    }

    private getAllstatistics(){
        return this._httpClient
            .get(API_URLS.statistics.getRadarStatistics.replace('{id}', this.id))
            .subscribe((res:any) => {        
                //  console.log('res', res);
                let data= Object.entries(res)
                this.thisMonth=data[0][1]
                this.lastMonth=data[1][1]
               this.thisMonth= this.thisMonth.map(item=>{
                    return ( {
                        x:item?.date===null?'':item.date,
                        y:item.count
                    })
                })
                this.lastMonth=this.lastMonth.map(item=>{
                    return ( {
                        x:item?.date===null?'':item.date,
                        y:item.count
                    })
                })
                  
                this.series={
                    'this-month':[{name:'المخالفات',data:this.thisMonth}],
                    'last-month':[{name:'المخالفات',data:this.lastMonth}]
                }
            
        
                
                   
                // console.log('series: ',this.series);
                // console.log('chartsxxxx',this.thisMonth);
                // console.log('chartsyyyyyyyy',this.lastMonth);
                
                        
                           this._prepareChartData(); 
    
                    
            });
    
      }

    /**
    * Fix the SVG fill references. This fix must be applied to all ApexCharts
    * charts in order to fix 'black color on gradient fills on certain browsers'
    * issue caused by the '<base>' tag.
    *
    * Fix based on https://gist.github.com/Kamshak/c84cdc175209d1a30f711abd6a81d472
    *
    * @param element
    * @private
    */
    private _fixSvgFill(element: Element): void {
        // Current URL
        const currentURL = this._router.url;

        // 1. Find all elements with 'fill' attribute within the element
        // 2. Filter out the ones that doesn't have cross reference so we only left with the ones that use the 'url(#id)' syntax
        // 3. Insert the 'currentURL' at the front of the 'fill' attribute value
        Array.from(element.querySelectorAll('*[fill]'))
            .filter(el => el.getAttribute('fill').indexOf('url(') !== -1)
            .forEach((el) => {
                const attrVal = el.getAttribute('fill');
                el.setAttribute('fill', `url(${currentURL}${attrVal.slice(attrVal.indexOf('#'))}`);
            });
    }
}
