import { Component } from '@angular/core';
import { BarFormat, BarFormatterParams, Logger } from 'ag-grid-community';

@Component({
  selector: 'app-confidence-renderer',
  templateUrl: './confidence-renderer.component.html',
  styleUrls: ['./confidence-renderer.component.scss']
})
export class ConfidenceRendererComponent {
  params: any;
  getLabelFunction: any;
formatterr:any
  agInit(params: any): void {
   // console.log('getLabelFunction: ',this.params.getLabelFunction);
    this.params = params;
    this.getLabelFunction = this.params.getLabelFunction;
params.formatter = this.formatter(params)
//console.log('getLabelFunction: ',this.params.getLabelFunction);
   
  }

  refresh(params?: any): boolean {
    return true;
  }
  isPopup(): boolean {
    return false;
  }
  
  formatter(params :any) {
    const  yValue  = params.value;
    return (yValue < 50 ? '#F55D51' : yValue >= 95 ? '' : '#FFB300')
    
}
}
