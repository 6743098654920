import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NzSelectModeType } from 'ng-zorro-antd/select';
import { EntityType } from '../../../../@aman/enums/entity-type.enum';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from '../../../../@aman/utils/API_URLS';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./select-input.component.scss'],
})
export class SelectInputComponent implements OnInit, OnDestroy {
    @Input() form: FormGroup;
    @Input() controlName: string = '';
    @Input() valueType: 'value' | 'objectId' | 'object' = 'value';
    @Input() options: any[];
    @Input() optionLabel: string = null;
    @Input() optionValue: string = null;
    @Input() entityType: EntityType;
    @Input() addLabel: boolean = true;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() prefixIcon: boolean = false;
    @Input() tags?: number;

    @Input() iconClass: string;
    @Input() multiple: NzSelectModeType = 'default';
    @Input() disabled: boolean = false;
    @Input() class: string = 'flex-auto';
    @Input() labelClasses: string;
    @Input() patternErrorMsg: string;
    @Input() errorClasses: string;
    @Input() inputEvent: Function;
    @Input() nzSize: string = 'default'
    @Input() compareWith?: Function = (o1, o2) => {
        // console.log(o1,o2,'asdf');

        return o1 === o2;
    };
    @Input() search?: boolean = false
    constructor(private _httpClient: HttpClient) { }
    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();

    }
    destroy$: Subject<boolean> = new Subject<boolean>();

    ngOnInit(): void {

        if (!this.options && this.entityType) {
            this._httpClient.get<any[]>(API_URLS[this.entityType].getAll, { params: {pageSize: 10000, page: 1} }).subscribe((result) => {
                console.log('res', result);

                this.options = result['items'] ? result['items'] : result
            })
        }
        // console.log('controls: ', this.form.controls);

    }



}
