<ng-container [formGroup]="form">
    <div [ngClass]="class" class="w-full">
        <!-- <mat-label *ngIf="addLabel" [ngClass]="labelClasses">{{ label }}</mat-label> -->
        <mat-label class="font-lg font-[500]" *ngIf="addLabel">{{label | transloco}}
            <span *ngIf="form.get(controlName)?.errors?.required"
                class="mat-mdc-form-field-required-marker mdc-floating-label--required"></span>
        </mat-label>

        <nz-input-group [nzPrefix]="inputClearTpl">
            <nz-select [nzShowArrow]="true" nzAllowClear nzShowIcon nzShowSearch nzHideUnMatched
                [formControlName]="controlName" [nzPlaceHolder]="placeholder" [nzMode]="multiple" [nzMaxTagCount]="tags"
                [nzMaxTagPlaceholder]="placeholder" [compareWith]="compareWith" [nzSize]="nzSize">
                <!-- <nz-option
                    nzValue=""
                    nzDisabled
                    nzLabel="-- {{ placeholder }} --"
                ></nz-option> -->
                <nz-option *ngFor="let option of options"
                    [nzValue]="valueType === 'object' ? option : valueType === 'objectId' ? {id: option[optionValue]} : option[optionValue]"
                    [nzLabel]="optionLabel ? option[optionLabel] : option"></nz-option>
            </nz-select>
        </nz-input-group>
        <ng-template #inputClearTpl>
            <mat-icon *ngIf="prefixIcon" class="icon-size-5" matPrefix [svgIcon]="iconClass"></mat-icon>
        </ng-template>

        <!-- <mat-hint *ngIf="hint">{{hint}}</mat-hint> -->

        <mat-error [ngClass]="errorClasses"
            *ngIf="form.get(controlName)?.hasError('required') && form.get(controlName)?.touched">
            {{label|transloco}} {{'Is Required' |transloco}}
        </mat-error>
        <mat-error [ngClass]="errorClasses" *ngIf="form.get(controlName)?.hasError('pattern')">{{patternErrorMsg |
            transloco}}</mat-error>
    </div>
</ng-container>