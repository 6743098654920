<div class="flex flex-col flex-auto w-full dark:border-b bg-card dark:bg-transparent ">

    <div class="flex flex-wrap w-full max-w-screen-xl mx-auto p-6 md:p-8">

        <!-- Title and action buttons -->
        <div class="flex items-center justify-between w-full">
            <div>
                <h2 class="text-3xl font-semibold tracking-tight leading-8">
                    {{radar.governorate?.name}}-{{radar?.road?.name}} {{radar?.roadDirection?.name}}
                    {{radar?.roadType?.name}} - {{radar.name}} <span class="text-2xl text-primary-500">
                        ({{radar?.vendor?.name}})</span></h2>
                <div class="font-medium tracking-tight text-secondary">{{'Keep track of radar status, analytics'
                    |transloco}}</div>
            </div>
            <div class="flex items-center">
                <div class="hidden sm:flex flex-wrap items-center my-3 mx-3">
                    <!-- status -->
                    <div class="p-4 leading-none rounded-l-0 border border-r-0 rtl:border-r"
                        *ngFor="let item of violationstatistics | keyvalue; let i = index">
                        <div class="text-sm font-medium text-secondary">{{item?.value?.name |transloco}}</div>
                        <div class="mt-2 font-mono text-xl">{{(item?.value?.count!== 0?item?.value?.count:0)
                            |ThousandSuffixe }}</div>
                    </div>
                    <div class="p-4 leading-none rounded-r-0 border">
                        <div class="text-sm font-medium text-secondary">{{'All Time' |transloco}}</div>
                        <div class="mt-2 font-mono text-xl">{{allTimeData|ThousandSuffixe}}</div>
                    </div>
                </div>
                <!-- <button
                    class="hidden sm:inline-flex mx-3"
                    mat-stroked-button>
                    <mat-icon
                        class="icon-size-5"
                        [svgIcon]="'heroicons_solid:document-report'"></mat-icon>
                    <span class="ml-2">{{'Reports' |transloco}}</span>
                </button> -->

                <button class="hidden sm:inline-flex" mat-flat-button [color]="'primary'"
                    [routerLink]="['/violation/violations-filter']" [queryParams]="{'radar':radar.id}"
                    routerLinkActive="router-link-active">
                    <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:save'"></mat-icon>
                    <span class="ml-2">{{'View Violations' |transloco}}</span>
                </button>

                <!-- Actions menu (visible on xs) -->
                <!-- <div class="sm:hidden">
                    <button
                        [matMenuTriggerFor]="actionsMenu"
                        mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:dots-vertical'"></mat-icon>
                    </button>
                    <mat-menu #actionsMenu="matMenu">
                        <button mat-menu-item>Export</button>
                        <button mat-menu-item>Reports</button>
                        <button mat-menu-item>Settings</button>
                    </mat-menu>
                </div> -->
            </div>
        </div>
        <!-- radar status -->
        <div class="flex items-center">
            <div [ngClass]="'flex-auto w-full'" class="w-full">
                <mat-radio-group aria-label="Select an option" [formControl]="radarStatus">
                    <mat-radio-button value="PENDING">{{"Pending"|transloco}}</mat-radio-button>
                    <mat-radio-button value="ACTIVE">{{"Active"|transloco}}</mat-radio-button>
                    <mat-radio-button value="INACTIVE">{{"Inactive"|transloco}}</mat-radio-button>
                </mat-radio-group>
            </div>

        </div>


    </div>
    <div class="px-10">

        <!-- <radar-violation-count-chart [radar]="radar"></radar-violation-count-chart> -->
        <radar-actions [radar]="radar"></radar-actions>
        <radar-history></radar-history>
    </div>

</div>