import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
    map,
    Observable,
    of,
    ReplaySubject,
    switchMap,
    tap,
    BehaviorSubject,
} from 'rxjs';
// import { User } from 'app/core/user/user.types';
import { User } from '@aman/types/user.type';
import { API_URLS } from '@aman/utils/API_URLS';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _user: BehaviorSubject<User> = new BehaviorSubject<User>(null);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        // this._user.next(value);
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    // get(): Observable<User>
    // {
    //     return this._httpClient.get<User>('api/common/user').pipe(
    //         tap((user) => {
    //             this._user.next(user);
    //         })
    //     );
    // }

    get(): Observable<User> {
        return this._user.asObservable();
        // // return this._httpClient.get<User>(API_URLS.Auth.loginByToken).pipe(
        //     tap((user) => {
        //         console.log("get user: ", user);

        //         this._user.next(user);
        //     })
        // );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    can(
        permission:
            | 'create'
            | 'read'
            | 'update'
            | 'delete'
            | 'lock'
            | 'unlock'
            | 'export',
        module: string
    ): Observable<boolean> {
        return this.user$.pipe(
            switchMap((user) => {
                if (user?.securityProfile != null) {
                    if (
                        user?.securityProfile?.lines?.some((line) => {
                            let method =
                                'can' +
                                permission.charAt(0).toUpperCase() +
                                permission.substring(1);
                            if (module === 'violationtable') {
                                module = 'violation';
                            }

                            return (
                                line.entityType?.toLowerCase() ===
                                    module?.toLowerCase() && line[method]
                            );
                        })
                    ) {
                        return of(true);
                    }
                } else {
                    return of(true);
                }
                return of(false);
            })
        );
    }
}
