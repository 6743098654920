import { AgGridData } from '@aman/types/agGridData.type';
import { OverrideService } from '@aman/utils/override.service';
import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { TranslocoService } from '@ngneat/transloco';
import { AgGridAngular } from 'ag-grid-angular';
import {
    CellClickedEvent,
    GridReadyEvent,
    ICellRendererParams,
    RowHeightParams,
    StatusPanelDef,
} from 'ag-grid-community';
import { ColDef } from 'ag-grid-enterprise';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { map, firstValueFrom } from 'rxjs';
import { UserService } from '../../../core/user/user.service';
import { AgDeleteRendererComponent } from '../ag-cell-renderer/ag-delete-renderer/ag-delete-renderer.component';
import { AgUpdateRendererComponent } from '../ag-cell-renderer/ag-update-renderer/ag-update-renderer.component';
import { ClickableStatusBarComponentComponent } from '../clickable-status-bar-component/clickable-status-bar-component.component';

@Component({
    selector: 'app-ag-grid',
    templateUrl: './ag-grid.component.html',
    styleUrls: ['./ag-grid.component.scss'],
})
export class AgGridComponent implements OnInit {
    @Input() agGridData: AgGridData;
    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

    deleteForm: FormGroup;

    constructor(
        private _httpClient: HttpClient,
        public override: OverrideService,
        private translate: TranslocoService,
        private _fuseConfirmationService: FuseConfirmationService,
        private notification: NzNotificationService,
        private fb: FormBuilder,
        public userService: UserService,
        private route: ActivatedRoute
    ) {}

    async ngOnInit(): Promise<void> {
        let url = await firstValueFrom(
            this.route.parent.url.pipe(
                map((url) => {
                    return url[0].path.replace('-', '');
                })
            )
        );
        if (typeof this.agGridData.rowData === 'string')
            this.getRowData(this.agGridData.rowData);

        if (await firstValueFrom(this.userService.can('update', url))) {
            if (this.agGridData.update) {
                this.agGridData.columnDefs = [
                    ...this.agGridData.columnDefs,
                    {
                        headerName: 'Edit',
                        field: 'id',
                        autoHeight: true,
                        onCellClicked: (ev) => {},
                        cellRenderer: AgUpdateRendererComponent,
                        headerValueGetter: this.localizeHeader.bind(this),
                        hide: false,
                    },
                ];
            }
        }
        if (await firstValueFrom(this.userService.can('delete', url))) {
            if (this.agGridData.delete) {
                this.agGridData.columnDefs = [
                    ...this.agGridData.columnDefs,
                    {
                        headerName: 'Delete',
                        field: 'id',
                        autoHeight: true,
                        onCellClicked: (ev) => {},
                        cellRenderer: AgDeleteRendererComponent,
                        headerValueGetter: this.localizeHeader.bind(this),
                        cellRendererParams: {
                            onClick: this.onDeleteClick.bind(this),
                        },
                        hide: false,
                    },
                ];
            }
        }

        this.initializeDeleteForm(this.agGridData.moduleName);
    }

    defaultColDef: ColDef = {
        sortable: true,
        onCellClicked: (event) => {
            if (this.agGridData.onCellCliecked) {
                this.agGridData.onCellCliecked(event);
            }
        },
        filter: true,
        headerValueGetter: this.localizeHeader.bind(this),
    };

    // Example load data from sever
    onGridReady(params: GridReadyEvent) {
        params.api.setDomLayout('autoHeight');
        params.api.sizeColumnsToFit();
        // this.setAgGridTableHeight('100%', '500px');
    }

    public localizeHeader(parameters: ICellRendererParams): string {
        let headerIdentifier = parameters.colDef.headerName;
        return this.translate.translate(headerIdentifier);
    }

    // Example using Grid's API
    clearSelection(): void {
        this.agGrid.api.deselectAll();
    }
    public statusBar: {
        statusPanels: StatusPanelDef[];
      } = {
        statusPanels: [
        //   {
        //     statusPanel: CountStatusBarComponent,
        //   },
          {
            statusPanel: ClickableStatusBarComponentComponent,
          },
          {
            statusPanel: 'agAggregationComponent',
            statusPanelParams: {
              aggFuncs: ['count', 'sum'],
            },
          },
        ],
      };
    getRowData(endPoint: string) {
        return this._httpClient
            .get(endPoint, {
                params: {
                    pageSize: 10000,
                },
            })
            .subscribe((res) => {
                // console.log('res',res);
                if (res['items']) {
                    this.agGridData.rowData = res['items'];
                } else {
                    this.agGridData.rowData = res;
                }
            });
    }

    initializeDeleteForm(moduleName: string) {
        return (this.deleteForm = this.fb.group({
            title: `${this.translate.translate(
                'Remove'
            )} ${this.translate.translate(this.capitalize(moduleName))}`,
            message: `${this.translate.translate(
                'Are you sure you want to remove this'
            )} ${this.translate.translate(
                this.capitalize(moduleName)
            )} ${this.translate.translate('permanently?')}
          <span class="font-medium block">${this.translate.translate(
              'This action cannot be undone!'
          )}</span>`,
            icon: {
                show: true,
                name: 'heroicons_outline:exclamation',
                color: 'warn',
            },
            actions: {
                confirm: {
                    show: true,
                    label: this.translate.translate('Remove'),
                    color: 'warn',
                },
                cancel: {
                    show: true,
                    label: this.translate.translate('Cancel'),
                },
            },
            dismissible: true,
        }));
    }

    openConfirmationDialog(data): void {
        // console.log("dataa: ", data);

        // Open the dialog and save the reference of it
        const dialogRef = this._fuseConfirmationService.open(
            this.deleteForm.value
        );

        // Subscribe to afterClosed from the dialog reference
        dialogRef.afterClosed().subscribe((result) => {
            if (result === 'confirmed') {
                this._httpClient
                    .delete(
                        this.agGridData.deleteEndpoint.replace('{id}', data.id)
                    )
                    .subscribe((res) => {
                        if (res['affected'] === 1) {
                            this.agGrid.api.applyTransaction({
                                remove: [data],
                            });

                            this.notification.success(
                                `${this.translate.translate(
                                    'Delete Confirmation'
                                )}`,
                                `${this.translate.translate(
                                    this.capitalize(this.agGridData.moduleName)
                                )} ${this.translate.translate(
                                    'has been deleted succefully.'
                                )}`,
                                { nzDuration: 3000 }
                            );
                        }
                    });
            }
            return;
        });
    }

    async onDeleteClick(event) {
        this.openConfirmationDialog(event.rowData.data);
    }

    capitalize(text: string) {
        return text.replace(/^\w|(?<=\s).{1}/g, function (char, index) {
            return char.toUpperCase();
        });
    }
}
