<ng-container [formGroup]="form">

    <div [ngClass]="class" class="w-full">
        <mat-label [ngClass]="labelClasses" *ngIf="addLabel">{{label | transloco}} 
        </mat-label>
        <nz-input-group [nzPrefix]="inputClearTpl" class="p-0">
            <!-- <nz-select ngModel="lucy" [formControlName]="controlName">
                <nz-option *ngFor="let record of options" nzValue={{record.optionValue}}
                    nzLabel={{record.optionLabel}}></nz-option>
            </nz-select> -->
            <nz-tree-select [nzExpandedKeys]="expandKeys" [nzCheckable]="nzCheckable"
             [nzMultiple]="nzMultiple" [nzNodes]="options"
             [nzShowSearch]="true" nzPlaceHolder="Please select"
             [nzMaxTagPlaceholder]="maxPlaceHolder"
                [formControlName]="controlName" class="p-0"></nz-tree-select>
        </nz-input-group>
        <ng-template #inputClearTpl>
            <mat-icon *ngIf="prefixIcon" class="icon-size-5" matPrefix [svgIcon]="iconClass"></mat-icon>
        </ng-template>

        <!-- <mat-hint *ngIf="hint">{{hint}}</mat-hint> -->

        <mat-error [ngClass]="errorClasses" *ngIf="form.get(controlName)?.hasError('required')">
            {{label}} {{'is required.'}}
        </mat-error>
        <mat-error [ngClass]="errorClasses" *ngIf="form.get(controlName)?.hasError('pattern')">{{patternErrorMsg | transloco}}</mat-error>
    </div>
</ng-container>
