import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { ICellEditorParams } from 'ag-grid-community';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
    selector: 'app-drop-down-renderer',
    templateUrl: './drop-down-renderer.component.html',
    styleUrls: ['./drop-down-renderer.component.scss'],
})
export class DropDownRendererComponent implements ICellEditorAngularComp {
    params: {
        control: FormControl,
        options: any[],
        label: string,
        initialValue: any,
        onChange: any;
    } & any;
    getLabelFunction: any;

    agInit(params: any): void {
        console.log('params', params);

        this.params = params;
        this.getLabelFunction = this.params.getLabelFunction;
        this.params.control.setValue(params.value)
        this.params.control.valueChanges.subscribe(value => {
            this.params.onChange({
                ...this.params.data,
                status: value
            })
            let colId = this.params.column.colId;
            this.params.node.setDataValue(colId, value);
        })
    }

    getValue() {
        return this.params.control.value
    }

    refresh(params?: ICellEditorParams): boolean {
        return true;
    }
    isPopup(): boolean {
        return false;
    }
}
