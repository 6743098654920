import { ISocketMessage } from '@aman/types/socket-message.type';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Socket } from 'ngx-socket-io';
import { Observable, of, switchMap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    constructor(private socket: Socket, private _snackBar: MatSnackBar) {}

    sendMessage(msg: string) {
        this.socket.emit('message', msg);
    }

    getNotifications(channel: string): Observable<ISocketMessage> {
        // console.log('channel', channel);

        return this.socket.fromEvent<ISocketMessage>(`channel_${channel}`).pipe(
            switchMap((notification) => {
                console.log(notification);

                this.playNotificationSound();
                this._snackBar.open(notification.title, notification.body);
                console.log(notification, '1234aaa');

                if (Notification.permission !== 'granted')
                    Notification.requestPermission();
                else {
                    var notificationVal = new Notification(notification.title, {
                        icon: '/assets/images/logo/logo-on-dark.png',
                        body: notification.body,
                    });
                }
                notificationVal.onclick = function () {
                    window.open('http://localhost:4200');
                };

                return of(notification);
            })
        );
    }

    removeListenerNotification(channel: string): any {
        return this.socket.removeListener(`channel_${channel}`);
    }

    playNotificationSound() {
        const audio = new Audio('assets/sounds/notification.mp3');
        audio.play();
    }
}
