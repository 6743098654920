<div class="flex flex-col flex-auto min-w-0 w-full test">

    <!-- Header -->
    <div
        class="w-full flex flex-col sm:flex-row flex-0 sm:items-center sm:justify-between p-2 border-b bg-card dark:bg-transparent">
        <div class="flex-1 min-w-0">
            <!-- Breadcrumbs -->
            <div class="flex flex-wrap items-center font-medium">
                <div>
                    <a class="whitespace-nowrap text-primary-500" routerLink="/">{{"Centeralized Platform" | transloco}}</a>
                </div>
                <div class="flex items-center ml-1 whitespace-nowrap" *ngIf="allRecordsPageData.section">
                    <mat-icon class="icon-size-5 text-secondary" class="origin-center rtl:scale-x-[-1]" [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                    <a class="ml-1 text-primary-500" [routerLink]="allRecordsPageData.section.link">{{allRecordsPageData.section.title | transloco}}</a>
                </div>
                <div class="flex items-center ml-1 whitespace-nowrap">
                    <mat-icon class="icon-size-5 text-secondary" class="origin-center rtl:scale-x-[-1]" [svgIcon]="'heroicons_solid:chevron-right'"></mat-icon>
                    <a class="ml-1 text-primary-500" [routerLink]="'./'">{{allRecordsPageData.title | transloco}}</a>
                </div>
            </div>

            <div class="flex flex-row justify-between">
                <div class="mt-2 flex felx cols">
                    <h2 class="text-3xl md:text-4xl font-extrabold tracking-tight leading-7 sm:leading-10 truncate">
                        {{allRecordsPageData.title | transloco}}
                        <h4 class="text-primary-500 text-2xl font-meduim leading-6 text-center mt-2" *ngIf="allRecordsPageData.dataCount > 0">
                             {{'Number Of Radars' |transloco}} {{allRecordsPageData.dataCount}}</h4>
                    </h2>
                </div>
                <div class="flex shrink-0 items-center mt-6 sm:mt-0 sm:ml-4">
                    <button class="ml-4 min-w-fit" mat-flat-button [color]="'accent'" *ngIf="allRecordsPageData.integrateWithLicensingSystem">
                        <mat-icon [svgIcon]="'mat_solid:sync'"></mat-icon>
                        <span class="ml-2 mr-1">{{"Integrate with Licensing System" | transloco}}</span>
                    </button>

                    <ng-content select="[UploadButton]"></ng-content>

                    <button class="ml-4 min-w-24" mat-flat-button [color]="'primary'" *ngIf="allRecordsPageData.add &&(userService.can('create',module) |async)" [routerLink]="'../add'">
                        <mat-icon [svgIcon]="'mat_solid:add'"></mat-icon>
                        <span class="ml-2 mr-1">{{"Add"| transloco}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="flex-auto p-2">
        <ng-content select="[RadarFilter]"></ng-content>
        <div class="w-full">
            <div [ngClass]="{'flex flex-col  px-1  bg-card shadow rounded-2xl overflow-hidden h-fit': allRecordsPageData.type === pageType.allData,
            'grid grid-cols-1 lg:grid-cols-4 lg:gap-4': allRecordsPageData.type === pageType.sectionDashboard}">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>
