import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { InterfaceGuard } from './shared/guards/interface.guard';
import { HomeScreen } from '@aman/types/user.type';
import { ResetPasswordGuard } from './core/auth/guards/reset-password.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed in user to the '/dashboards/project'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {
        path: 'signed-in-redirect',
        pathMatch: 'full',
        redirectTo: 'dashboard',
    },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then((m) => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then((m) => m.AuthForgotPasswordModule),
            },
            // {
            //     path: 'reset-password',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/auth/reset-password/reset-password.module'
            //         ).then((m) => m.AuthResetPasswordModule),
            // },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
        ],
    },

    // Reset Password
    {
        path: '',
        canActivate: [ResetPasswordGuard],
        canActivateChild: [ResetPasswordGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'reset-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then((m) => m.AuthResetPasswordModule),
            },
        ]
    },

    // Employee routes
    {
        path: 'employee',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'enterprise',
            interface: [HomeScreen.EMPLOYEE]

        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: '',
                loadChildren: () =>
                    import(
                        'app/modules/aman-platform/employee-dashboard/employee-dashboard.module'
                    ).then((m) => m.EmployeeDashboardModule),
            },
            // 404 & Catch all
            {
                path: '404-not-found',
                pathMatch: 'full',
                loadChildren: () =>
                    import(
                        'app/modules/admin/pages/error/error-404/error-404.module'
                    ).then((m) => m.Error404Module),
            },
            { path: '**', redirectTo: '404-not-found' },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,

        data: {
            interface: null,
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    // {
    //     path: '',
    //     component: LayoutComponent,
    //     data: {
    //         layout: 'empty',
    //     },
    //     children: [
    //         {
    //             path: 'home',
    //             loadChildren: () =>
    //                 import('app/modules/landing/home/home.module').then(
    //                     (m) => m.LandingHomeModule
    //                 ),
    //         },
    //     ],
    // },

    // Admin routes

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },   data: {
            interface: [HomeScreen.ADMIN,HomeScreen.VENDOR]
        },

        children: [
            {
                path: 'dashboard',
                // canActivate: [InterfaceGuard],
                // canActivateChild: [InterfaceGuard],

                loadChildren: () =>
                    import(
                        'app/modules/aman-platform/main-dashboard/main-dashboard.module'
                    ).then((m) => m.MainDashboardModule),
            },
            //Violation Definition Routes
            {
                path: 'violation-definition',

                loadChildren: () =>
                    import(
                        'app/modules/aman-platform/violation-definition/violation-definition.module'
                    ).then((m) => m.ViolationDefinitionModule),
            },

            // Basic Data Routes
            {
                path: 'basic-data',

                loadChildren: () =>
                    import(
                        'app/modules/aman-platform/basic-data/basic-data.module'
                    ).then((m) => m.BasicDataModule),
            },
            // Violation Sources
            {
                path: 'violation-sources',

                loadChildren: () =>
                    import(
                        'app/modules/aman-platform/violation-sources/violation-sources.module'
                    ).then((m) => m.ViolationSourcesModule)

            },
            // system-constants
            {
                path: 'system-constants',

                loadChildren: () =>
                    import(
                        'app/modules/aman-platform/system-constants/system-constants.module'
                    ).then((m) => m.SystemConstantsModule),
            },
            // user Management
            {
                path: 'user-management',

                loadChildren: () =>
                    import(
                        'app/modules/aman-platform/user-management/user-management.module'
                    ).then((m) => m.UserManagementModule),
            },
            // settings
            {
                path: 'settings',

                loadChildren: () =>
                    import(
                        'app/modules/aman-platform/settings/settings.module'
                    ).then((m) => m.SettingsModule),
            },
            {
                path: 'violation',

                loadChildren: () =>
                    import(
                        'app/modules/aman-platform/violation/violation.module'
                    ).then((m) => m.ViolationModule),
            },
            {
                path: 'payment',

                loadChildren: () =>
                    import(
                        'app/modules/aman-platform/payment/payment.module'
                    ).then((m) => m.PaymentModule),
            },
            // GIS
            {
                path: 'gis',
                loadChildren: () => import('app/modules/aman-platform/gis/gis.module').then((m) => m.GisModule)
            },
            {
                path: 'reports',
                loadChildren: () =>
                    import('app/modules/aman-platform/reports/reports.module').then((m) => m.ReportsModule),
            },
            // {
            //     path: 'employee',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/aman-platform/employee-dashboard/employee-dashboard.module'
            //         ).then((m) => m.EmployeeDashboardModule),
            // },
            {
                path: 'authority-integration',
                loadChildren: () => import('app/modules/aman-platform/authority-integration/authority-integration.module').then((m) => m.AuthorityIntegrationModule),
            },

            // 404 & Catch all
            {
                path: '404-not-found',
                pathMatch: 'full',
                loadChildren: () =>
                    import(
                        'app/modules/admin/pages/error/error-404/error-404.module'
                    ).then((m) => m.Error404Module),
            },
            { path: '**', redirectTo: '404-not-found' },
        ],
    },

];
