import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import "ag-grid-enterprise";
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { DatePipe, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { socketUri } from '@aman/utils/API_URLS';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RadarDashboardModule } from './modules/aman-platform/gis/radar-dashboard/radar-dashboard/radar-dashboard.module';
// import { provideClientHydration } from '@angular/platform-browser';

registerLocaleData(en);

const routerConfig: ExtraOptions = {
    // preloadingStrategy: PreloadAllModules,
    // scrollPositionRestoration: 'enabled',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    urlUpdateStrategy: 'eager',
    anchorScrolling: 'enabled',
    initialNavigation: 'enabledNonBlocking'

};


const config: SocketIoConfig = {
    url: socketUri, options: {
        autoConnect: true,
        reconnection: true,
        reconnectionDelay: 3000,
        transports: ['websocket']

    }
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,

        RouterModule.forRoot(appRoutes, {
    initialNavigation: 'enabledBlocking'
}),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,
        SocketIoModule.forRoot(config),

        MatSnackBarModule,
        // Layout module of your application
        LayoutModule,
        FormsModule,
        HttpClientModule,
        MatSnackBarModule,
        RadarDashboardModule
    ],
    bootstrap: [AppComponent],
    providers: [
        { provide: NZ_I18N, useValue: en_US },
        DatePipe,
        // provideClientHydration()


    ],
})
export class AppModule { }
