
<div class="my-2 mr-auto">
    <!-- Dismiss button -->

  
    <button class="fuse-alert-dismiss-button" mat-icon-button (click)="onPrevClick()">
        <mat-icon [svgIcon]="'mat_outline:arrow_forward_ios'"></mat-icon>
    </button>
  <label class="text-slate-800">{{'Page' |transloco}} <strong class="text-blue-500"> {{currentPage+1 |number}}</strong> {{'Of' |transloco}} <strong class="text-blue-500"> {{totalPages |number}}</strong></label>
  

  
    <button  class="fuse-alert-dismiss-button" mat-icon-button (click)="onNextClick()">
        <mat-icon [svgIcon]="'mat_outline:arrow_back_ios'"></mat-icon>
    </button>

    <label class="text-slate-800">{{'Page Size' |transloco}}</label>

    <nz-select
    class="ag-status-bar-page-size-select"
    [nzSize]="'large'"
    [nzOptions]="pageSizes"
    [ngModel]="pageSize"
    (ngModelChange)="onPageSizeChange($event)"
  >
  </nz-select>
  


</div>