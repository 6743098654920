import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadarActionsComponent } from '../radar-actions/radar-actions.component';
import { RadarDashboardComponent } from '../radar-dashboard.component';
import { RadarHistoryComponent } from '../radar-history/radar-history.component';
import { RadarViolationCountChartComponent } from '../radar-violation-count-chart/radar-violation-count-chart.component';
import { SharedModule } from '../../../../../shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
    imports: [SharedModule],
    declarations: [
        RadarDashboardComponent,
        RadarViolationCountChartComponent,

        RadarHistoryComponent,
        RadarActionsComponent,
    ],
    exports: [
        RadarDashboardComponent,
        RadarViolationCountChartComponent,

        RadarHistoryComponent,
        RadarActionsComponent,
    ],
})
export class RadarDashboardModule {}
