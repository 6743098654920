
<fuse-card class="flex flex-col w-full px-8 pt-6 pb-4 filter-list shadow-xl shadow-slate-200">
  <div class="flex items-center justify-between">
      <div class="text-2xl font-semibold leading-tight">{{parent |transloco}}</div>
   
  </div>
  <form [formGroup]="form">
    <nz-tree
    #tree
      [nzData]="nodes"
      
      nzCheckable
      nzMultiple
      nzShowLine
      nzShowExpand
      (nzCheckBoxChange)="onItemChecked($event)"
    ></nz-tree>
  </form>

</fuse-card>
