<ng-container [formGroup]="form">

    <div [ngClass]="class" class="w-full">
      <!-- <mat-label *ngIf="addLabel" [ngClass]="labelClasses">{{ label }}</mat-label> -->

  <mat-checkbox 
  class="example-margin"
  [formControlName]="controlName"
  [color]="color">
  {{label}}
</mat-checkbox>

    </div>
</ng-container>