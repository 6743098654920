import { API_URLS, imageServiceUri } from '@aman/utils/API_URLS';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-image-input',
    templateUrl: './image-input.component.html',
    styleUrls: ['./image-input.component.scss'],
    // encapsulation: ViewEncapsulation.None
})
export class ImageInputComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() hint: string = null;
    @Input() class: string = 'flex-auto';
    @Input() labelClasses: string;
    @Input() patternErrorMsg: string;
    @Input() errorClasses: string;
    @Output('press') press = new EventEmitter<string>();
    @Output('imgURL') imgURL = new EventEmitter<string>();
    uploadUrl = API_URLS.File.upload;
    loading = false
    previewImage: any;

    constructor(private _httpClient: HttpClient,private changeDetector: ChangeDetectorRef) { }
    ngOnInit(): void {
        this.form.get(this.controlName).valueChanges.subscribe((value)=>{

            // let value = this.form.get(this.controlName)?.value

            if (value){
                this.defaultFileList = [
                    // ...this.defaultFileList,
                    {
                        uid: '-1',
                        name: value,
                        url: imageServiceUri +  value,
                        thumbUrl: imageServiceUri +  value,

                    }
                ]
                this.changeDetector.markForCheck()
            }
            console.log(this.defaultFileList);

        })




    }

    previewVisible= false;
    defaultFileList: NzUploadFile[] =[
        // {
        //     "uid": "-1",
        //     "name": "/usr/SystemUploads/1676992661-WhatsApp Image 2022-12-26 at 22.06.36.jpg",
        //     "url": "http://54.226.39.74:5001/image/usr/SystemUploads/1676992661-WhatsApp Image 2022-12-26 at 22.06.36.jpg",
        //     "thumbUrl": "http://54.226.39.74:5001/image/usr/SystemUploads/1676992661-WhatsApp Image 2022-12-26 at 22.06.36.jpg"
        // }
    ]
    getBase64(img: File, callback: (img: string) => void): void {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result!.toString()));
        reader.readAsDataURL(img);
    }


    uploadFile(event) {

        switch (event.file.status) {
            case 'uploading':
                this.loading = true;
                break;
            case "done":
                this.imgURL.emit(event.file?.response.Path)
                break;
            case 'error':
                console.log('Network error');
                this.loading = false;
                break;
        }
        // console.log('this.avatarUrl', this.avatarUrl);

        //return
    }
    handlePreview = async (file: NzUploadFile): Promise<void> => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj!);
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      };

}
const getBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
