import { LicenseType } from '@aman/types/licenseType.type';
import { SkipReason } from '@aman/types/skipReason.type copy';
import { ViolationType } from '@aman/types/violationType.type';
import { API_URLS } from '@aman/utils/API_URLS';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, map, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReviewerBasicDataService {

    _basicData: Observable<{ skipReasons: SkipReason[], violationTypes: ViolationType[] }>
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) { }


    loadBasicData(): Observable<{ skipReasons: SkipReason[], violationTypes: ViolationType[] }> {
        return this._basicData = forkJoin({
            skipReasons: this._httpClient.get<SkipReason[]>(API_URLS.SkipReason.getAll).pipe(map((res) => res)),
            violationTypes: this._httpClient.get<ViolationType[]>(API_URLS.ViolationTypes.getAll).pipe(map((res) => res)),
            // licenseTypes: this._httpClient.get<LicenseType[]>(API_URLS.LicenseType.getAll).pipe(map((res) => res))
        })
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get basicData$(): Observable<{ skipReasons: SkipReason[], violationTypes: ViolationType[] }> {
        return this._basicData
    }
}
