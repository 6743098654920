import { Radar } from '@aman/types/radar.type';
import { Overlay } from '@angular/cdk/overlay';
import {
    ChangeDetectorRef,
    Component,
    Renderer2,
    ViewContainerRef,
    OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDrawerToggleResult } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { Subject, takeUntil } from 'rxjs';
import { GisService } from '../gis.service';
import { RadarMapComponent } from '../radar-map/radar-map.component';
import { RadarDrawerService } from 'app/shared/radar-drawer.service';
import { API_URLS } from '../../../../../@aman/utils/API_URLS';
import { HttpClient, HttpParams } from '@angular/common/http';

@Component({
    selector: 'app-radar-dashboard',
    templateUrl: './radar-dashboard.component.html',
    styleUrls: ['./radar-dashboard.component.scss'],
})
export class RadarDashboardComponent implements OnInit {
    radar: Radar = this._activatedRoute.snapshot.data.radar;
    id: any;
    radarForm: FormGroup;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    violationstatistics = {
        skipped: { name: 'Skipped', count: 0 },
        pending: { name: 'Pending', count: 0 },
        sent: { name: 'Sent', count: 0 },
    };
    allTimeData = 0;
    radarStatus = new FormControl(this.radar?.status);
    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _radarDrawerService: RadarDrawerService,
        private _httpClient: HttpClient,
        private route: ActivatedRoute
    ) {}

    /**
     * On init
     */
    ngOnInit(): void {
        console.log('radar', this.radar);

        // Open the drawer
        this._radarDrawerService?.matDrawer?.open();
        this.id = this.route.snapshot.paramMap.get('id');
        console.log('id', this.id);

        this.radarStatus.valueChanges.subscribe((newValue) => {
            console.log(newValue);
            this._httpClient
                .put(
                    API_URLS.Radar.changeStatus.replace('{id}', this.id),
                    null,
                    { params: { status: newValue } }
                )
                .subscribe((res) => {
                    console.log('result', res);
                });
        });

        // Get the radars
        // this._gisService.radars$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((radars: Radar[]) => {
        //         this.radars = radars;

        //         // Mark for check
        //         this._changeDetectorRef.markForCheck();
        //     });

        // Get the radar
        // this._gisService.radar$
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe((radar: Radar) => {

        //         // Open the drawer in case it is closed
        //         this._radarDrawerService.matDrawer.open();

        //         // Get the radar
        //         this.radar = radar;

        //         // Mark for check
        //         this._changeDetectorRef.markForCheck();
        //     });
        let parameters = {};
        if (this.id) {
            parameters['radarId'] = this.id;
        }
        //  console.log('parameters', parameters);

        let queryParams = new HttpParams({ fromObject: parameters });

        this.getRadarstatistics(queryParams);
    }

    private getRadarstatistics(params) {
        return this._httpClient
            .get(API_URLS.statistics.radarByStatus, { params: params })
            .subscribe((res: any) => {
                console.log('radar', res);

                if (res.length > 0) {
                    res.forEach((rec) => {
                        this.allTimeData += rec.count;

                        switch (rec.id) {
                            case 'SKIPPED':
                            case 'DELETED':
                            case 'INVALID':
                                this.violationstatistics.skipped.count +=
                                    rec.count;
                                break;

                            case 'PENDING':
                            case 'INREVIEW':
                            case 'MUSTREVIEW':
                            case 'RECIEVING':
                                this.violationstatistics.pending.count +=
                                    rec.count;
                                break;

                            case 'SENT':
                            case 'AWAITINGSEND':
                            case 'FAILDTOSEND':
                            case 'COMPLAINED':
                            case 'PAID':
                                this.violationstatistics.sent.count +=
                                    rec.count;
                                break;

                            default:
                                break;
                        }
                    });
                }
            });
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
    /**
     * Close the drawer
     */
    closeDrawer(): Promise<MatDrawerToggleResult> {
        return this._radarDrawerService.matDrawer.close();
    }
}
