import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-tree-select',
    templateUrl: './tree-select.component.html',
    styleUrls: ['./tree-select.component.scss'],
})
export class TreeSelectComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() controlName: string = '';
    @Input() options: [] = [];
    @Input() optionLabel: string = null;
    @Input() optionValue: string = null;
    @Input() addLabel: boolean = true;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() prefixIcon: boolean = false;
    @Input() iconClass: string;
    @Input() multiple: boolean = false;
    @Input() disabled: boolean = false;
    @Input() class: string = 'flex-auto';
    @Input() labelClasses: string;
    @Input() patternErrorMsg: string;
    @Input() errorClasses: string;
    @Input() inputEvent: Function;
    @Input() maxPlaceHolder:string

    @Input() nzCheckable: boolean = false
    @Input() nzMultiple: boolean = false
    constructor() {}

    ngOnInit(): void {}

    expandKeys = ['100', '1001'];
    value?: string;
}
