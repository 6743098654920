import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() controlName: string;
    @Input() addLabel: boolean = true;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() prefixIcon: boolean = false;
    @Input() iconClass: string;
    @Input() hint: string = null;
    @Input() min: number;
    @Input() minLength: number;
    @Input() maxLength: number;
    @Input() max: number;
    @Input() type: 'text' | 'email' | 'password' | 'tel' | 'number' | 'textarea' | 'rang';
    @Input() class: string = 'flex-auto';
    @Input() labelClasses: string;
    @Input() patternErrorMsg: string;
    @Input() errorClasses: string;
    @Input() nzSize: string='default'
    @Output('press') press = new EventEmitter<string>();
    constructor() { }

    ngOnInit(): void {  }

}
