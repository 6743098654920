<ng-container [formGroup]="form">

    <div [ngClass]="class" class="w-full">

        <mat-label *ngIf="addLabel" [ngClass]="labelClasses">{{ label }}</mat-label>
   <br>
        <nz-range-picker 
        [formControlName]="controlName"
        [nzShowTime]="nzShowTime" 
        [nzRanges]="ranges"
        nzFormat="yyyy-MM-dd HH:mm:ss"
        (ngModelChange)="onChange($event)">
    </nz-range-picker>
<br>

<mat-error [ngClass]="errorClasses" *ngIf="form.get(controlName)?.hasError('required')">{{label}} {{'is required.'}}</mat-error>

    </div>


</ng-container>