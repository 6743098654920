import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DisabledTimeFn, DisabledTimePartial, NzDatePickerComponent } from 'ng-zorro-antd/date-picker';

import { differenceInCalendarDays, setHours } from 'date-fns';
import { TranslocoService } from '@ngneat/transloco';
import { startOfMonth, endOfMonth, endOfDay, startOfDay, startOfWeek, endOfWeek, startOfQuarter, endOfQuarter } from 'date-fns';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent {
  constructor(private translate: TranslocoService) { }
  ngOnInit(): void {
    this.ranges[this.translate.translate('Today')] = [startOfDay(new Date()), endOfDay(new Date())]
    this.ranges[this.translate.translate('This Week')] = [startOfWeek(new Date()), endOfWeek(new Date())]
    this.ranges[this.translate.translate('Last Three Months')] = [startOfQuarter(new Date()), endOfQuarter(new Date())]
    this.ranges[this.translate.translate('This Month')] = [startOfMonth(new Date()), endOfMonth(new Date())]
  }


  ranges = {}

  @Input() form: FormGroup;
  @Input() class: string = 'flex-auto';
  @Input() controlName: string = '';
  @Input() label: string;
  @Input() addLabel: boolean = true;
  @Input() showRanges: boolean = true;
  @Input() errorClasses: string;
  @Input() nzShowTime: boolean = false;
  @Input() prefixIcon: boolean = false;
  @Input() placeholder: string;
  @Input() iconClass: string;
  @Input() labelClasses: string;

  @ViewChild('endDatePicker') endDatePicker!: NzDatePickerComponent;



  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endDatePicker.open();
    }
  }
}