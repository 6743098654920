import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AllDataComponent } from './generic-components/all-data/all-data.component';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridComponent } from './generic-components/ag-grid/ag-grid.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { TextInputComponent } from './generic-forms/text-input/text-input.component';
import { ControlViewComponent } from './generic-components/control-view/control-view.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { SelectInputComponent } from './generic-forms/select-input/select-input.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { TreeSelectComponent } from './generic-forms/tree-select/tree-select.component';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { CheckBoxComponent } from './generic-forms/check-box/check-box.component';
import { DatePickerComponent } from './generic-forms/date-picker/date-picker.component';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateRangeComponent } from './generic-forms/date-range/date-range.component';
import { SwitchComponent } from './generic-forms/switch/switch.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { ImageInputComponent } from './generic-forms/image-input/image-input.component';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { SwitchButtonComponent } from './generic-components/ag-cell-renderer/switch-button/switch-button.component';
import { SimpleButtonComponent } from './generic-components/ag-cell-renderer/simple-button/simple-button.component';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { AgDeleteRendererComponent } from './generic-components/ag-cell-renderer/ag-delete-renderer/ag-delete-renderer.component';
import { AgUpdateRendererComponent } from './generic-components/ag-cell-renderer/ag-update-renderer/ag-update-renderer.component';
import { ResetPasswordRendererComponent } from './generic-components/ag-cell-renderer/reset-password-renderer/reset-password-renderer.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { RadioButtonComponent } from './generic-forms/radio-button/radio-button.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzPopoverModule } from 'ng-zorro-antd/popover';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgApexchartsModule } from 'ng-apexcharts';
import {MatSelectModule} from '@angular/material/select';
import { ConfidenceRendererComponent } from './generic-components/confidence-renderer/confidence-renderer.component';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { LeafletMapComponent } from './generic-components/leaflet-map/leaflet-map.component';
import { ImageServicePipe } from './pipes/image-service.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgOptimizedImage } from '@angular/common';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTableModule } from 'ng-zorro-antd/table';
import { AngularPinturaModule } from '@pqina/angular-pintura';
import { FuseCardModule } from '../../@fuse/components/card';
import { NzImageModule } from 'ng-zorro-antd/image';
import { FuseConfirmationModule } from '@fuse/services/confirmation';
import {MatRadioModule} from '@angular/material/radio';
import { RadarFilterComponent } from 'app/modules/aman-platform/violation/filters-components/radar-filter/radar-filter.component';
import { ThousandSuffixesPipe } from '../modules/aman-platform/main-dashboard/thousand-suffixes.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RadarDashboardComponent } from '../modules/aman-platform/gis/radar-dashboard/radar-dashboard.component';
import { RadarHistoryComponent } from '../modules/aman-platform/gis/radar-dashboard/radar-history/radar-history.component';
import { RadarViolationCountChartComponent } from '../modules/aman-platform/gis/radar-dashboard/radar-violation-count-chart/radar-violation-count-chart.component';
import { RadarActionsComponent } from '../modules/aman-platform/violation/violation-details/radar-actions/radar-actions.component';
import { RadarDashboardModule } from '../modules/aman-platform/gis/radar-dashboard/radar-dashboard/radar-dashboard.module';
import {MatExpansionModule} from '@angular/material/expansion';
import { RadarTreeComponent } from './generic-forms/radar-tree/radar-tree.component';
import { DropDownComponent } from './generic-forms/drop-down/drop-down.component';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { RolelTreeComponent } from './rolel-tree/rolel-tree.component';
import { MatTreeModule } from '@angular/material/tree';
import { DropDownRendererComponent } from './generic-components/ag-cell-renderer/drop-down-renderer/drop-down-renderer.component';
import { ClickableStatusBarComponentComponent } from './generic-components/clickable-status-bar-component/clickable-status-bar-component.component';
const materialModules = [
    MatFormFieldModule,
    MatIconModule,
    MatCheckboxModule,
    MatInputModule,
    MatInputModule,
    // MatSelectModule,
    MatTooltipModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatStepperModule,
    MatCardModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatRippleModule,
    MatDividerModule ,
    MatMenuModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule ,
    NgApexchartsModule,
    MatSelectModule,
    MatMenuModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatExpansionModule,
    MatTreeModule
];

const antdModules = [
    NzSelectModule,
    NzPopoverModule,
    NzInputModule,
    NzSwitchModule,
    NzTreeSelectModule,
    NzDatePickerModule,
    NzNotificationModule,
    NzUploadModule,
    NzButtonModule,
    NzIconModule,
    NzRadioModule,
    NzStatisticModule,
    NzProgressModule,
    NzSegmentedModule,
    NzModalModule,
    NzTableModule,
    NzImageModule,
    NzTreeModule
];

@NgModule({

    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        materialModules,
        antdModules,
        NgApexchartsModule,
        AgGridModule,
        TranslocoModule,
        NgApexchartsModule,
        NgxImageZoomModule,
        DragDropModule,
        NgOptimizedImage,
        AngularPinturaModule,
        FuseCardModule,
        FuseConfirmationModule
    ],

    declarations: [
        AllDataComponent,
        AgGridComponent,
        ControlViewComponent,
        TextInputComponent,
        SelectInputComponent,
        TreeSelectComponent,
        CheckBoxComponent,
        DatePickerComponent,
        DateRangeComponent,
        SwitchComponent,
        ImageInputComponent,
        SwitchButtonComponent,
        SimpleButtonComponent,
        AgDeleteRendererComponent,
        AgUpdateRendererComponent,
        ResetPasswordRendererComponent,
        RadioButtonComponent,
        ConfidenceRendererComponent,
        LeafletMapComponent,
        ImageServicePipe,
        RadarFilterComponent,
        ThousandSuffixesPipe,
        RadarTreeComponent,
        DropDownComponent,
        RolelTreeComponent,
        DropDownRendererComponent,
        ClickableStatusBarComponentComponent,



    ],

    exports: [
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        TranslocoModule,
        AllDataComponent,
        AgGridComponent,
        materialModules,
        antdModules,
        NgApexchartsModule,
        ControlViewComponent,
        SelectInputComponent,
        TreeSelectComponent,
        CheckBoxComponent,
        DatePickerComponent,
        DateRangeComponent,
        SwitchComponent,
        ImageInputComponent,
        NgxImageZoomModule,
        DragDropModule,
        TextInputComponent,
        SwitchButtonComponent,
        SimpleButtonComponent,
        RadioButtonComponent,
        LeafletMapComponent,
        NgApexchartsModule,
        ImageServicePipe,
        NgOptimizedImage,
        AngularPinturaModule,
        FuseCardModule,
        FuseConfirmationModule,
        RadarFilterComponent,
        ThousandSuffixesPipe,
        RadarTreeComponent,
        DropDownComponent,
        RolelTreeComponent

    ],
})
export class SharedModule {}
