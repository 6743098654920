
<ag-grid-angular
    style="width: 100%; height: 100%;"
    class="ag-theme-alpine min-h-screen"
    [rowHeight]="agGridData.rowHeight"
    [columnDefs]="agGridData.columnDefs"
    [defaultColDef]="agGridData.defaultColDef ? agGridData.defaultColDef : defaultColDef"
    [rowData]="agGridData.rowData"
    [rowSelection]="'multiple'"
    [rowHeight]="30"
    [animateRows]="true"
    (gridReady)="onGridReady($event)"
    [pagination]="agGridData.pagination"
    [suppressPaginationPanel]="agGridData.pagination"
    [paginationPageSize]="agGridData.paginationSize"
    [statusBar]="statusBar"
    [localeText]="override.agGridLocale"
    [enableRtl]="override.isRTL"
    [sideBar]="agGridData.sideBar"
></ag-grid-angular>
