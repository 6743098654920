<div class="fixed lg:sticky top-0 bottom-0 lg:left-full w-full sm:w-96 lg:w-16 lg:h-screen lg:shadow">
    <div
        class="flex flex-col w-full sm:w-96 h-full transition-transform duration-400 ease-drawer bg-card"
        [ngClass]="{'-translate-x-full sm:-translate-x-[-24rem] lg:-translate-x-[-20rem] shadow': opened && overrideService.isRTL,
            '-translate-x-full sm:-translate-x-[24rem] lg:-translate-x-[20rem] shadow': opened && !overrideService.isRTL,
            'translate-x-0': !opened}">

        <!-- Header -->
        <div
            class="quick-chat-header flex flex-0 items-center justify-start cursor-pointer"
            (click)="toggle()">

            <!-- Toggle -->
            <ng-container *ngIf="!opened || (opened && !selectedChat)">
                <div class="flex flex-auto items-center justify-center">
                    <div class="flex flex-0 items-center justify-center w-16">
                        <mat-icon
                            class="icon-size-6"
                            [svgIcon]="'mat_solid:queue'"></mat-icon>
                    </div>
                    <div class="text-lg font-medium text-secondary">{{'Violation Queues' | transloco}}</div>
                    <button
                        class="ml-auto mr-4"
                        mat-icon-button>
                        <mat-icon [svgIcon]="'heroicons_outline:x'"></mat-icon>
                    </button>
                </div>
            </ng-container>

        </div>

        <!-- Content -->
        <div class="flex flex-auto flex-col border-t overflow-hidden bg-slate-200 p-3">

            <!-- Reviewed Violations -->
            <fuse-card class="flex flex-col w-full px-6 pt-4 pb-3 filter-list" *ngIf="reviewQueue">
                <div class="flex items-center justify-between">
                    <div class="text-2xl font-semibold leading-tight">{{'Reviewed Violations'|transloco}}</div>
                </div>

                <div class="flex flex-col mt-3 overflow-auto">

                    <div class="flex items-center mb-2" *ngFor="let vg of reviewQueue">
                        <img
                            class="w-14 h-14 mr-4 rounded object-cover"
                            [src]="vg?.images?.plate | imageService"
                            alt="Card cover image">
                        <div class="flex flex-col min-w-0 w-full">
                            <div class="flex justify-between items-center">
                                <div class="font-medium leading-none">{{ formatPlateNumber(vg?.plateNumber) }}</div>

                                <button mat-mini-fab color="primary" aria-label="Undo Button" (click)="undo(vg?.id)">
                                    <mat-icon svgIcon="mat_solid:undo"></mat-icon>
                                </button>
                            </div>
                            <div class="text-md truncate">{{ formatViolations(vg?.violations) }}</div>
                        </div>
                    </div>

                </div>
            </fuse-card>


            <!-- Divider -->
            <div class="w-full h-1 bg-slate-400 my-4"></div>


            <!-- To be Reviewed Violations -->
            <fuse-card class="flex flex-col w-full px-6 pt-4 pb-3 filter-list" *ngIf="historyQueue">
                <div class="flex items-center justify-between">
                    <div class="text-2xl font-semibold leading-tight">{{'To be Reviewed Violations'|transloco}}</div>
                </div>

                <div class="flex flex-col mt-3 overflow-auto">

                    <div class="flex items-center mb-2" *ngFor="let vg of historyQueue">
                        <img
                            class="w-14 h-14 mr-4 rounded object-cover"
                            [src]="vg?.images?.plate | imageService"
                            alt="Card cover image">
                        <div class="flex flex-col min-w-0 w-full">
                            <div class="flex justify-between items-center">
                                <div class="font-medium leading-none">{{ formatPlateNumber(vg?.plateNumber) }}</div>
                            </div>
                            <div class="text-md truncate">{{ formatViolations(vg.data?.violations) }}</div>
                        </div>
                    </div>

                    <!-- <div class="flex items-center">
                        <img
                            class="w-14 h-14 mr-4 rounded object-cover"
                            src="http://54.226.39.74:5001/image/usr/Violations/2023/1/1/8/violation_5001/plateImage.png"
                            alt="Card cover image">
                        <div class="flex flex-col min-w-0">
                            <div class="font-medium leading-none">{{ 'ن ك و | 1589' }}</div>
                            <div class="text-md truncate mt-1">{{'تجاوز السرعة المقررة، عكس اتجاه السير'}}</div>
                        </div>
                    </div>
                    <div class="flex items-center mt-6">
                        <img
                            class="w-14 h-14 mr-4 rounded object-cover"
                            src="http://54.226.39.74:5001/image/usr/Violations/2023/1/19/6/violation_5000/plateImage.png"
                            alt="Card cover image">
                        <div class="flex flex-col min-w-0">
                            <div class="font-medium leading-none">{{ 'م ي ب | 758' }}</div>
                            <div class="text-md truncate mt-1">{{'قيادة السيارة بدون حزام أمان، التوقف في الممنوع'}}</div>
                        </div>
                    </div>
                    <div class="flex items-center mt-6">
                        <img
                            class="w-14 h-14 mr-4 rounded object-cover"
                            src="http://54.226.39.74:5001/image/usr/Violations/2023/1/1/8/violation_5001/plateImage.png"
                            alt="Card cover image">
                        <div class="flex flex-col min-w-0">
                            <div class="font-medium leading-none">{{ 'ح ز م | 7532' }}</div>
                            <div class="text-md truncate mt-1">{{'إستخدام التليفون يدويًا أثناء القيادة، عكس اتجاه السير'}}</div>
                        </div>
                    </div>
                    <div class="flex items-center mt-6">
                        <img
                            class="w-14 h-14 mr-4 rounded object-cover"
                            src="http://54.226.39.74:5001/image/usr/Violations/2023/1/19/6/violation_5000/plateImage.png"
                            alt="Card cover image">
                        <div class="flex flex-col min-w-0">
                            <div class="font-medium leading-none">{{ 'ع م م | 8529' }}</div>
                            <div class="text-md truncate mt-1">{{'إنتهاء رخصة التسيير'}}</div>
                        </div>
                    </div> -->
                </div>
            </fuse-card>

        </div>
    </div>
</div>

<!-- Select chat or start new template -->
<ng-template #selectChatOrStartNew>
    <div class="flex flex-col flex-auto items-center justify-center w-full h-full p-4">
        <mat-icon
            class="icon-size-24"
            [svgIcon]="'heroicons_outline:chat'"></mat-icon>
        <div class="mt-4 text-xl text-center font-medium tracking-tight text-secondary">Select a conversation</div>
    </div>
</ng-template>

<!-- Speech bubble tail SVG -->
<!-- @formatter:off -->
<ng-template #speechBubbleExtension>
    <svg width="100%" height="100%" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M1.01522827,0.516204834 C-8.83532715,54.3062744 61.7609863,70.5215302 64.8009949,64.3061218 C68.8074951,54.8859711 30.1663208,52.9997559 37.5036011,0.516204834 L1.01522827,0.516204834 Z" fill="currentColor" fill-rule="nonzero"></path>
        </g>
    </svg>
</ng-template>
<!-- @formatter:on -->
