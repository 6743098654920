<ng-container [formGroup]="form">

    <mat-label *ngIf="label" [ngClass]="labelClasses" class="">{{ label |transloco}}</mat-label>

    <div [ngClass]="class" class="w-full mt-2">
        <!-- <mat-label *ngIf="label" [ngClass]="labelClasses">{{ label }}</mat-label> -->
        <nz-tree-select class="w-full" nzShowIcon [nzShowSearch]="true" nzHideUnMatched [nzAsyncData]="true"
            [nzDropdownMatchSelectWidth]="false" nzCheckable [nzPlaceHolder]="placeholder"
            [formControlName]="controlName" nzShowExpand nzShowLine nzBackdrop nzAllowClear [nzSize]="'large'"
            [nzMaxTagCount]="tags" [nzMaxTagPlaceholder]="placeholder" nzVirtualHeight="50vh" [nzNodes]="options"
            [nzDefaultExpandAll]="false" [nzMultiple]="multiple" (ngModelChange)="onChange($event)"
            [nzMaxTagPlaceholder]="omittedPlaceHolder" [ngClass]="treeClass"></nz-tree-select>
        <ng-template #omittedPlaceHolder>{{'and' |transloco}} {{ form.get(controlName).value?.length - tags }}
            {{'more'|transloco}} ..</ng-template>
        <!-- <ng-template #omittedPlaceHolder let-omittedValues>{{'and' |transloco}} {{ form.get(controlName).value?.length }}
                 {{'more'|transloco}} ...</ng-template> -->
        <!-- nzVirtualHeight="300px" -->
    </div>
</ng-container>