import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';
import { environment } from './environments/environment';
if (environment.production) {
    window.console.log = () => { }
    // window.console.error = () => { }
    window.console.error = () => { }
    window.console.warn = () => { }
    enableProdMode();
}

function bootstrap() {
     platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.info(err));
   };


 if (document.readyState === 'complete') {
   bootstrap();
 } else {
   document.addEventListener('DOMContentLoaded', bootstrap);
 }
 
