
    <nz-tree-select
        style="width: 100%"
        class="my-2"
       [nzNodes]="nodes"
        [(ngModel)]="value"
        (ngModelChange)="onChange($event)"
        nzShowSearch
        nzCheckable
        nzAllowClear
        nzHideUnMatched  
        [nzMaxTagCount]="2"
       [nzAsyncData]="true" 
      [nzDefaultExpandAll]="true"
        [nzDropdownStyle]="{ 'max-height': '300px',width: '300px' }"
        nzPlaceHolder="{{'Select Radars'|transloco}}"
        [nzDropdownClassName]="'ag-custom-component-popup'"
    ></nz-tree-select>
    
    <!-- <div class="p-2">
        <radar-tree 
        [form]="form" 
        [controlName]="'radarTree'"
        tags="2" 
        class="flex-auto mt-1 p-0"
        [labelClasses]="'font-lg font-[500] mb-2'"
      ></radar-tree>

    </div>  -->