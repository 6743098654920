<div class="relative flex flex-col flex-auto min-w-0 overflow-hidden">

    <!-- Main -->
    <div class="flex flex-col flex-auto px-6 py-10 sm:px-16 sm:pt-18 sm:pb-20">

        <!-- Activity feed -->
        <div class="w-full max-w-3xl">

            <!-- Title -->
            <div class="text-4xl font-extrabold tracking-tight leading-none">{{'changes history' |transloco}}</div>

            <ng-container *ngIf="radarHistory; else loading">
                <ng-container *ngIf="radarHistory?.length > 0; else noActivity">
                    <div class="mt-8">
                        <ol>
                            <!-- Activities -->
                            <ng-container *ngFor="let history of radarHistory; let i = index; let first = first; let last = last; trackBy: trackByFn">

                                <!-- Date separator -->
                                <ng-container *ngIf="first || !isSameDay(history.date, radarHistory[i - 1].date)">
                                    <li class="relative flex py-7">
                                        <div class="relative py-2 px-8 text-md font-medium leading-5 rounded-full bg-primary text-on-primary">
                                            {{getRelativeFormat(history?.date) |transloco}}
                                        </div>
                                    </li>
                                </ng-container>

                                <!-- Activity -->
                                <li class="relative flex py-7">
                                    <!-- Line -->
                                    <ng-container *ngIf="!last && isSameDay(history?.date, radarHistory[i + 1].date)">
                                        <div class="absolute top-7 w-0.5 h-full  -ml-px  bg-gray-300 dark:bg-gray-600" [ngClass]="{'left-5  ': !override.isRTL,'right-5  ': override.isRTL}"></div>
                                    </ng-container>

                                    <div class="relative flex flex-auto">
                                        <!-- Icon -->
                                        <ng-container>
                                            <div class="flex shrink-0 items-center justify-center w-10 h-10  rounded-full"
                                            [ngClass]="{' mr-4   ': !override.isRTL,' ml-4   ': override.isRTL ,'bg-green-400': history.online ===true, 
                                            'bg-red-500':history.online ===false}">
                                                <mat-icon
                                                    class="icon-size-5 text-white"
                                                    [svgIcon]="'mat_outline:online'">
                                                </mat-icon>
                                            </div>
                                        </ng-container>

                                        <!-- Image -->
                                        <!-- <ng-container *ngIf="activity.image">
                                            <img
                                                class="shrink-0 w-10 h-10 mr-4 rounded-full overflow-hidden object-cover object-center"
                                                [src]="activity.image"
                                                [alt]="'Activity image'">
                                        </ng-container> -->

                                        <!-- Content -->
                                        <div class="flex flex-col flex-auto items-start">
                                            <!-- Description -->
                                            <ng-container>
                                                <div>
                                                    {{history?.online?('Radar is Online'|transloco) :('Radar is Off'|transloco)}}
                                                </div>
                                            </ng-container>
                                            <div class="flex flex-col sm:flex-row sm:items-center mt-2 sm:mt-1 sm:space-x-2 text-md leading-5">
                                                <!-- Date -->
                                                <div class="text-secondary">
                                                    {{history.date | date:'MMM dd, h:mm a':"UTC+00:00"}}
                                                </div>
                                                <!-- Linked content -->
                                                <!-- <ng-container *ngIf="activity.linkedContent">
                                                    <div class="hidden sm:block">&bull;</div>
                                                    <-- Internal link --
                                                    <ng-container *ngIf="activity.useRouter">
                                                        <a
                                                            class="cursor-pointer text-primary"
                                                            [routerLink]="activity.link">
                                                            {{activity.linkedContent}}
                                                        </a>
                                                    </ng-container> -->
                                                    <!-- External link -->
                                                    <!-- <ng-container *ngIf="!activity.useRouter">
                                                        <a
                                                            class="cursor-pointer text-primary"
                                                            [href]="activity.link"
                                                            target="_blank">
                                                            {{activity.linkedContent}}
                                                        </a>
                                                    </ng-container>
                                                </ng-container> -->
                                            </div>
                                            <!-- Extra content -->
                                            <!-- <ng-container *ngIf="activity.extraContent">
                                                <div
                                                    class="mt-4 py-4 px-5 rounded-lg bg-gray-200 dark:bg-gray-800"
                                                    [innerHTML]="activity.extraContent"></div>
                                            </ng-container> -->
                                        </div>
                                    </div>
                                </li>
                            </ng-container>
                        </ol>
                    </div>
                </ng-container>
            </ng-container>

            <!-- Loading template -->
            <ng-template #loading>
                {{'Loading...'|transloco}}
            </ng-template>

            <!-- No Activity template -->
            <ng-template #noActivity>
                {{'There are is no data at the moment...'|transloco}}
            </ng-template>

        </div>
    </div>

</div>
