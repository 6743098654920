<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>


<!-- Wrapper -->
<div class="flex flex-col flex-auto items-center w-full min-w-0 bg-gray-200 dark:bg-card">

    <!-- Header -->
    <!-- Top bar -->
    <div
        class="relative dark flex flex-auto justify-center w-full px-4 py-1 md:px-8 h- bg-gray-800 dark:bg-gray-900 max-h-20">
        <div class="flex items-center w-full max-w-360 h-16 sm:h-">
            <!-- Logo -->
            <ng-container *ngIf="!isScreenSmall">
                <div class="flex items-center" [routerLink]="['/','employee']">
                    <img class="w-24 cursor-pointer" src="assets/images/logo/logo-on-dark.png" alt="Logo image">
                </div>
            </ng-container>
            <!-- Navigation toggle button -->
            <ng-container *ngIf="isScreenSmall">
                <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                    <mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
                </button>
            </ng-container>
            <!-- Components -->
            <div class="flex items-center  space-x-0.5 sm:space-x-2"
                [ngClass]="override.isRTL? 'pr-2 mr-auto':'pl-2 ml-auto'">
                <!-- <languages></languages> -->
                <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                <!-- <search [appearance]="'bar'"></search> -->
                <!-- <shortcuts></shortcuts> -->
                <!-- <messages></messages> -->
                <notifications></notifications>
                <!-- <button class="lg:hidden" mat-icon-button (click)="quickChat.toggle()"> -->
                <!-- <button class="lg:hidden" mat-icon-button>
                    <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
                </button> -->
                <user></user>
            </div>
        </div>
    </div>
    <!-- Bottom bar -->

    <!-- Content -->
    <div class="flex flex-auto justify-center w-full sm:p-6 md:py-4">
        <div
            class="flex flex-col flex-auto w-full sm:max-w-480 sm:shadow-lg sm:rounded-lg sm:overflow-hidden bg-default">
            <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
                 Otherwise, layout changes won't be registered and the view won't be updated! -->
            <router-outlet *ngIf="true"></router-outlet>
        </div>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 justify-center w-full px-6 md:px-8 z-49 border-t bg-card print:hidden"> -->
    <!-- <div class="flex items-center w-full max-w-360 h-14 sm:h-20">
            <span class="font-medium text-secondary">Shelter Technology &copy; {{currentYear}}</span>
        </div>
    </div> -->

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->