<ng-container [formGroup]="form">
    <div [ngClass]="class" class="w-full my-2">
        <mat-form-field apperance="fill" class="w-full">
            <mat-label  class="font-lg font-[500]" *ngIf="label"> {{label | transloco}}</mat-label>
            <mat-select [formControlName]="controlName" placeholder="{{label |transloco}}" [multiple]="multiple" 
            [disabled]="disabled">
              
                <mat-option *ngFor="let option of options" [value]="option">
                    {{option |transloco }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get(controlName)?.hasError('required')"> {{label|transloco}} {{'Is Required' |transloco}}</mat-error>
        </mat-form-field>
        </div>
    </ng-container>