import { Component } from '@angular/core';
import { IStatusPanelAngularComp } from 'ag-grid-angular';
import { GridApi, IStatusPanelParams } from 'ag-grid-community';
import { NzSelectOptionInterface } from 'ng-zorro-antd/select';

@Component({
  selector: 'app-clickable-status-bar-component',
  templateUrl: './clickable-status-bar-component.component.html',
  styleUrls: ['./clickable-status-bar-component.component.scss']
})
export class ClickableStatusBarComponentComponent implements IStatusPanelAngularComp {
   gridApi: GridApi;
   pageSize: number;
   rowCount: number;
  
 
  // Customize the available page sizes here
  pageSizes: NzSelectOptionInterface[] = [10, 50, 100, 1000, -1].map((a) => {
    return {
      label: a === -1 ? 'عرض الكل': a,
      value: a
    }
  });

  agInit(params: IStatusPanelParams): void {
    this.gridApi = params.api;
    this.pageSize = this.gridApi.paginationGetPageSize();
    this.pageSizes.push({
      label : this.pageSize,
      value: this.pageSize
    })
    this.pageSizes.sort((a,b)=> a.value-b.value)
    this.rowCount = this.gridApi.paginationGetRowCount();
  }

  onPageSizeChange(newPageSize: number): void {
    if (newPageSize === -1) {
      this.gridApi.paginationSetPageSize(Number.MAX_VALUE);
    } else {
      this.gridApi.paginationSetPageSize(newPageSize);
    }
  }

  onPrevClick(): void {
    this.gridApi.paginationGoToPreviousPage();
  }

  onNextClick(): void {
    this.gridApi.paginationGoToNextPage();
  }

  public get currentPage() : number {
    return this.gridApi.paginationGetCurrentPage()
  }
  

  public get totalPages() : number {
    return this.gridApi.paginationGetTotalPages()
  }
  



  
  // public get isFirstPage() : boolean {
  //   return this.gridApi.paginationGet();
  // }
 
  
  // public get isLastPage() : boolean {
  //   return this.gridApi.paginationIsLastPage();
  // }
  

}
