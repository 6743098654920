export enum EntityType {
  User = 'User',
  CarColor = 'CarColor',
  CarMake = 'CarMake',
  CarModel = 'CarModel',
  Governorate = 'Governorate',
  LicenseDate = 'LicenseDate',
  LicenseType = 'LicenseType',
  Officer = 'Officer',
  RadarDevice = 'RadarDevice',
  RadarHistory = 'RadarHistory',
  Radar = 'Radar',
  ReportCategory = 'ReportCategory',
  ReportDefinition = 'ReportDefinition',
  ReviewQueue = 'ReviewQueue',
  ReviewRoom = 'ReviewRoom',
  Road = 'Road',
  RoadDirection = 'RoadDirection',
  RoadType = 'RoadType',
  SecurityLine = 'SecurityLine',
  SecurityProfile = 'SecurityProfile',
  SkipReason = 'SkipReason',
  SpeedMapException = 'SpeedMapException',
  SpeedMap = 'SpeedMap',
  UserActivity = 'UserActivity',
  Vendor = 'Vendor',
  ViolationGroupHistory = 'ViolationGroupHistory',
  ViolationGroup = 'ViolationGroup',
  ViolationType = 'ViolationType',
  Violation = 'Violation',
  TrackingList = 'TrackingList',
  Notifications = 'Notifications',
  Settings = 'Settings',
  ReportPortal = 'ReportPortal',
  Payment = 'Payment'
}

export const EntityTypeArray = [
  'User',
  'CarColor',
  'CarMake',
  'CarModel',
  'Governorate',
  'LicenseDate',
  'LicenseType',
  'Officer',
  'RadarDevice',
  'RadarHistory',
  'Radar',
  'ReportCategory',
  'ReportDefinition',
  'ReviewQueue',
  'ReviewRoom',
  'Road',
  'RoadDirection',
  'RoadType',
  'SecurityLine',
  'SecurityProfile',
  'SkipReason',
  'SpeedMapException',
  'SpeedMap',
  'UserActivity',
  'Vendor',
  'ViolationGroupHistory',
  'ViolationGroup',
  'ViolationType',
  'Violation',
  'TrackingList',
  'Notifications',
  'Settings',
  'ReportPortal',
  'Payment'
] as EntityType[];
