import { AllRecordsPageData } from '@aman/types/allRecordsPageData';
import { Component, Input, OnInit } from '@angular/core';
import { PageType } from '@aman/types/allRecordsPageData';
import { UserService } from '../../../core/user/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';


@Component({
    selector: 'app-all-data',
    templateUrl: './all-data.component.html',
    styleUrls: ['./all-data.component.scss']
})
export class AllDataComponent implements OnInit {
    constructor(public userService: UserService, private route: ActivatedRoute) { }
    @Input() allRecordsPageData: AllRecordsPageData;
    pageType = PageType;
    module: string;
    async ngOnInit(): Promise<void> {
        this.module = await firstValueFrom(this.route.parent.url.pipe(map((url) => {
            return url[0].path.replace('-', '');
        })))
    }
}
