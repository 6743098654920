import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
// import { TranslateService } from '@ngx-translate/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
// import { locale } from '../../app/navigation/i18n/ar';
import { HttpHeaders } from '@angular/common/http';

import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { FuseConfigService } from '../../@fuse/services/config';
import { AG_GRID_LOCALE_AR } from './ag-grid-locale.ar';
import { AG_GRID_LOCALE_EN } from './ag-grid-locale.en';
import { AppConfig } from '../../app/core/config/app.config';
import { API_URLS } from './API_URLS';
// import { ar } from 'date-fns/locale';
import en from '@angular/common/locales/en';
import ar from '@angular/common/locales/ar';
import { registerLocaleData } from '@angular/common';


@Injectable({
  providedIn: 'root',
})
export class OverrideService {
  language: Observable<string>;
  userTypeO: Observable<string>;
  userType$: BehaviorSubject<string> = new BehaviorSubject('');
  login$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  FuseConfig$: BehaviorSubject<string> = new BehaviorSubject('modern');
  isAuthenticated = false;
  private token: string;
  config: AppConfig;
  constructor(
    private translate: TranslocoService,
    private titleService: Title,
    private fuseConfigService: FuseConfigService,
    private router: Router,
    private _fuseConfigService: FuseConfigService,

  ) {
    this.language = of(localStorage.getItem('language') ?? 'en');
    this.userTypeO = of(atob(sessionStorage.getItem(btoa('userType'))) ?? 'admin');
    this._fuseConfigService.config$
      .subscribe((config: AppConfig) => {

        // Store the config
        this.config = config;
      });

  }

  agGridLocale: {
    [key: string]: string;
  } = this.translate.getActiveLang() === 'ar' ? AG_GRID_LOCALE_AR : AG_GRID_LOCALE_EN;
  getAuthStatus() {
    return this.login$.asObservable();
  }
  getToken() {
    return sessionStorage.getItem('token')
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  public imageUrl = API_URLS.File.download;


  public violationImageUrl(ip, path: string): string {
    return `http://${ip}:3305/File/view?imgpath=${path}`;
  }

  autoAuthUser() {
    const authInformation = this.getToken();
    const userTypeInformation = this.userType;


    if (authInformation == null && userTypeInformation == "ée") {
      console.log('auth', authInformation, userTypeInformation);
      this.router.navigate(['/pages/auth/login'])
      return;
    }
    this.token = authInformation;
    this.isAuthenticated = true;
    this.login$.next(true);
    this.userType$.next(userTypeInformation);


  }

  logOut() {
    sessionStorage.clear();
    localStorage.clear()
    this.isAuthenticated = false;
    this.login$.next(false);
    this.userType$.next('');
  }
  getUserTypeAsObservable() {
    return this.userType$.asObservable()
  }
  public get userType(): string {
    return atob(sessionStorage.getItem(btoa('userType')));
  }

  public get loggedIn(): boolean {

    return sessionStorage.getItem('token') != null;

  }
  // Languages
  get currentLang() {
    return this.translate.getActiveLang()
  }
  get lang(): Observable<string> {
    return this.language;
  }


  get otherLang() {
    switch (localStorage.getItem('language')) {
      case 'en':
        return 'ar';
      case 'ar':
        return 'en';
      case 'tr':
        return 'tr';
      default:
        return 'en';
    }

  }

  switchLanguage(language: string, key?: string) {
    //   if (this.currentLang == language) {
    this.translate.setActiveLang(language);
    this.language = of(language);
    registerLocaleData(language === 'en' ? en : ar);

    localStorage.setItem("language", language ?? 'en');
    this.setTitle()
    this.setStyle();

  }
  // inverseLang(keyLang) {
  //   return keyLang === 'en' ? 'ar' : 'en';
  // }
  // // Title
  setTitle() {
    return this.translate.langChanges$.subscribe((data) => {
      this.titleService.setTitle(this.translate.translate('Aman'));
    });
  }

  // // Style
  setStyle() {
    const keyLang = localStorage.getItem('language') || this.currentLang || this.translate.getDefaultLang();

    // this.fuseConfigService.config = {
    //   layout: localStorage.getItem('Layout'),
    //   color: 'indigo',
    //   direction: keyLang === 'ar' ? 'rtl' : 'ltr'
    // }
    // const keyLang = localStorage.getItem('language') || this.currentLang || this.translate.getDefaultLang();
    const htmlElement = document.getElementsByTagName('html')[0];
    const headElements = document.getElementsByTagName('head')[0];

    htmlElement.setAttribute('lang', keyLang);
    if (keyLang === 'ar') {
      htmlElement.setAttribute('dir', 'rtl');
    } else {
      htmlElement.setAttribute('dir', 'ltr');
    }

  }

  public get AuthHeaders(): HttpHeaders {
    return new HttpHeaders({
      authorization: 'Bearer ' + 'Y29tLm92ZXJyaWRlZWcubW9kZXJuLWFjYWRlbXkuY29tcHV0ZXIuZGVsZWdhdGlvbg='
    })
  }
  public get LoginHeaders(): HttpHeaders {
    return new HttpHeaders({
      authorization: 'Bearer ' + 'Y29tLm92ZXJyaWRlZWcubW9kZXJuLWFjYWRlbXkuY29tcHV0ZXIuZGVsZWdhdGlvbg='
    })
  }

  public get FilesAuthHeaders(): HttpHeaders {
    return new HttpHeaders({
      authorization: 'Bearer ' + 'Y29tLm92ZXJyaWRlZWcubW9kZXJuLWFjYWRlbXkuY29tcHV0ZXIuZGVsZWdhdGlvbg='
    })
  }

  public get isRTL(): boolean {
    let rtl = false;
    this.translate.langChanges$.subscribe(lang => {
      lang == 'ar' ? rtl = true : rtl = false;
    });
    return rtl;
  }


  public get dir(): 'rtl' | 'ltr' {
    return this.isRTL ? 'rtl' : 'ltr'
  }

  public FuseConFiguration(config) {
    return config;
  }
  getFuseConfig() {
    return this.FuseConfig$.asObservable()
  }


}



export function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}
