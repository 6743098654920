<ng-container [formGroup]="form">

    <mat-form-field [ngClass]="class" class="w-full">
        <mat-label *ngIf="addLabel" [ngClass]="labelClasses">{{ label }}
            <!-- <span *ngIf="form.get(controlName)?.errors?.required"
                class="mat-mdc-form-field-required-marker mdc-floating-label--required"></span> -->
        </mat-label>

        <input [formControlName]="controlName" matInput [placeholder]="placeholder" [type]="type" [min]="min"
            [max]="max" (click)="press.emit(form.get(controlName).value)">
        <mat-icon *ngIf="prefixIcon" class="icon-size-5" matPrefix [svgIcon]="iconClass"></mat-icon>

        <mat-hint *ngIf="hint">{{hint}}</mat-hint>
        <!-- <mat-error [ngClass]="errorClasses"
            *ngIf="form.get(controlName)?.hasError('required') && form.get(controlName)?.touched">
            {{label|transloco}} {{'Is Required' |transloco}}
        </mat-error> -->
        <mat-error [ngClass]="errorClasses" *ngIf="form.get(controlName)?.hasError('required')">
            {{label}} {{'is required'|transloco}}</mat-error>
        <mat-error [ngClass]="errorClasses" *ngIf="form.get(controlName)?.errors?.minlength">
            {{label}} {{'length must be more than or equal to'|transloco}}
            {{form.get(this.controlName).errors?.minlength?.requiredLength}}</mat-error>
        <mat-error [ngClass]="errorClasses" *ngIf="form.get(controlName)?.errors?.maxlength">{{label}}
            {{'length must be less than or equal to'|transloco}}
            {{form.get(this.controlName).errors?.maxlength?.requiredLength}}</mat-error>
        <mat-error [ngClass]="errorClasses" *ngIf="form.get(controlName)?.hasError('pattern')">{{patternErrorMsg |
            transloco}}</mat-error>
        <mat-error *ngIf="form.get(controlName)?.hasError('keyUsedBefore')"> {{form.get(controlName).value}}
            {{'Used Before' |transloco}}</mat-error>
    </mat-form-field>
</ng-container>