import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface MyCellParams{
buttonText?:string
}

@Component({
  selector: 'app-simple-button',
  template: `
    <!-- <p>
      simple-button works!
    </p> -->
    <button mat-button color="primary" >{{buttonText}}</button>
  `,
  styles: [
  ]
})
export class SimpleButtonComponent implements ICellRendererAngularComp {
  buttonText:string=''
  agInit(params: ICellRendererParams & MyCellParams): void {
   // this.isActive=params.value
   this.buttonText=params.buttonText ??'Default'
   }
 
   refresh(params?: ICellRendererParams): boolean {
     return false;
   }

}
