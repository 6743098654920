import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { getISOWeek } from 'date-fns';

import { startOfMonth,endOfMonth , endOfDay,startOfDay,startOfWeek,endOfWeek,startOfQuarter,endOfQuarter} from 'date-fns';

@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {
  constructor(private translate: TranslocoService){}
  ngOnInit(): void {
    this.ranges[this.translate.translate('Today')] =[startOfDay(new Date()), endOfDay(new Date())]
    this.ranges[this.translate.translate('This Week')] =[startOfWeek(new Date()), endOfWeek(new Date())]
    this.ranges[this.translate.translate('Last Three Months')] =[startOfQuarter(new Date()), endOfQuarter(new Date())]
    this.ranges[this.translate.translate('This Month')] =[startOfMonth(new Date()), endOfMonth(new Date())]
  }

  ranges = {}
  date = null;
  @Input() form: FormGroup;
  @Input() class :string = 'flex-auto';
    @Input() controlName: string = '';
    @Input() label: string;
    @Input() errorClasses: string;
  @Input() nzShowTime: boolean = false;
  @Input() addLabel: boolean = true;
  @Input() prefixIcon: boolean = false;
  @Input() placeholder: string;

  @Input() labelClasses: string;
  @Input() iconClass: string;




  onChange(result: Date[]): void {
    console.log('onChange: ', result);
  }

  getWeek(result: Date[]): void {
    console.log('week: ', result.map(getISOWeek));
  }
}


