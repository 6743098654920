import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OverrideService } from '../../../@aman/utils/override.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor
{
    /**
     * Constructor
     */
    constructor(private _snackBar: MatSnackBar, private _authService: AuthService,private override: OverrideService) {
        
    }

    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        // Clone the request object
        let newReq = req.clone();

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if ( this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken) )
        {
            newReq = req.clone({
                headers: req.headers.set('Authorization', 'Bearer ' + this._authService.accessToken)
            });
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {

                // Catch "401 Unauthorized" responses
                if (!req.url.includes('/Auth/login') && error instanceof HttpErrorResponse && error.status === 401) {
                    // Sign out

                   
                    this._snackBar.open(error.error?.message ?? 'Error', error.error?.status ?? 'Error', { duration: 3000 , direction: this.override.isRTL? 'rtl':'ltr' });

                    this._authService.signOut();
    
                    // Reload the app
                    // location.reload();
                    // Reload the app
                    // location.reload();ß
                }
                if (error instanceof HttpErrorResponse) {
                    // console.log(error.error);

                    let err = error.error;


                    this._snackBar.open(err?.message ?? 'Error', err?.status ?? 'Error', { duration: 3000 , direction: this.override.isRTL? 'rtl':'ltr' });
                }


                return throwError(() => error);
            })
        );
    }
}
