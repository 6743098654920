import { OverrideService } from '@aman/utils/override.service';
import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Activity } from 'app/modules/admin/pages/activities/activities.types';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { API_URLS } from '@aman/utils/API_URLS';
import { Radar } from '@aman/types/radar.type';

@Component({
    selector: 'radar-history',
    templateUrl: './radar-history.component.html',
    styleUrls: ['./radar-history.component.scss']
})
export class RadarHistoryComponent implements OnInit {
    activities$: Observable<Activity[]>;
    id: any;
    radarHistory:any =  []
    constructor(private transloco: TranslocoService,
         public override: OverrideService,
         private _httpClient: HttpClient,
         private route: ActivatedRoute
         ) { }

    ngOnInit(): void {
        
      this.id = this.route.snapshot.paramMap.get('id');

    //   console.log(this.id);

      this._httpClient
          .get(API_URLS.Radar.getHistory.replace('{id}', this.id))
          .subscribe((res) =>{
            // console.log('radar history : ',res);
            this.radarHistory=res
            // console.log('radaaarHistory : ',this.radarHistory);
            
            
          })
        this.activities$ = of([
            {
                id: '493190c9-5b61-4912-afe5-78c21f1044d7',
                icon: 'mat_outline:online',
                description: '<strong>Radar is Online</strong>',
                date: moment().subtract(25, 'minutes').toISOString(),
            },

            {
                id: '493190c9-5b61-4912-afe5-78c21f1044d7',
                icon: 'mat_outaline:online',
                description: 'Ahmed Hassan Disabled Flash',
                date: moment().subtract(6, 'hours').toISOString(), // 5 hours ago

            },
            {
                id: '493190c9-5b61-4912-afe5-78c21f1044d7',
                icon: 'mat_outline:online',
                description: 'Offline Detected',
                date: moment().subtract(7, 'hours').toISOString(), // 7 hours ago
            },
            {
                id: '493190c9-5b61-4912-afe5-78c21f1044d7',
                icon: 'mat_outline:online',
                description: '<strong>Radar is off</strong>',
                date: moment().subtract(9, 'hours').toISOString() // 9 hours ago
            },


            {
                id: '493190c9-5b61-4912-afe5-78c21f1044d7',
                icon: 'mat_outline:online',
                description: '<span class="text-red-500">Offline Detected</span>',
                date: moment().subtract(1, 'day').toISOString(),
            },
            {
                id: '493190c9-5b61-4912-afe5-78c21f1044d7',
                icon: 'mat_outline:online',
                description: '<strong>Radar is offline</strong>',
                date: moment().subtract(1, 'day').toISOString(),
            },
            {
                id: '493190c9-5b61-4912-afe5-78c21f1044d7',
                icon: 'mat_outline:online',
                description: '<span class="text-red-500">Radar is Online</span>',
                date: moment().subtract(2, 'day').toISOString(),
            },
        ])
    }

    /**
       * Returns whether the given dates are different days
       *
       * @param current
       * @param compare
       */
    isSameDay(current: string, compare: string): boolean {
        return moment(current, moment.ISO_8601).isSame(moment(compare, moment.ISO_8601), 'day');
    }

    /**
     * Get the relative format of the given date
     *
     * @param date
     */
    getRelativeFormat(date: string): string {
        const today = moment().startOf('day');
        const yesterday = moment().subtract(1, 'day').startOf('day');

        // Is today?
        if (moment(date, moment.ISO_8601).isSame(today, 'day')) {
            return this.transloco.translate('Today');
        }

        // Is yesterday?
        if (moment(date, moment.ISO_8601).isSame(yesterday, 'day')) {
            return this.transloco.translate('Yesterday');
        }
        //    console.log('date: ',moment(date, moment.ISO_8601).fromNow())
        return this.transloco.translate(moment(date, moment.ISO_8601).fromNow());
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }
}
