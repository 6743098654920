import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { firstValueFrom, Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from 'app/core/user/user.service';
import { HomeScreen } from '@aman/types/user.type';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private userService: UserService,
        private _router: Router
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can activate
     *
     * @param route
     * @param state
     */
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl, route);
    }

    /**
     * Can activate child
     *
     * @param childRoute
     * @param state
     */
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl, childRoute);
    }

    // /**
    //  * Can load
    //  *
    //  * @param route
    //  * @param segments
    //  */
    // canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean
    // {
    //     return this._check('/',route.);
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private async _check(
        redirectURL: string,
        childRoute: ActivatedRouteSnapshot
    ): Promise<boolean> {
        // Check the authentication status
        let authenticated = await this._authService.check();

        // If the user is not authenticated...
        if (!authenticated) {
            // Redirect to the sign-in page
            this._router.navigate(['sign-in'], {
                queryParams: { redirectURL },
            });

            // Prevent the access
            return false;
        } else {
            let user = await firstValueFrom(this.userService.user$);

            let interfaces = (childRoute.data.interface ||
                childRoute.parent.data.interface) as HomeScreen[];

            // console.log("user: ", user, interfaces, childRoute.url.map((u) => u.path).toString());

            if (!user) {
                await this._router.navigate(['/', 'sign-in']);
                return false;
            }

            if (user.otp) {
                await this._router.navigate(['/', 'reset-password']);
                return false;
            }

            if (
                interfaces &&
                !interfaces?.some(
                    (intefce) =>
                        intefce.toLowerCase() ==
                        user?.homeScreen.toString().toLowerCase()
                )
            ) {
                // console.log("user2: ", user.homeScreen, interfaces, childRoute.url.map((u) => u.path).toString());

                // this._router.navigate(['/',`${user.homeScreen.toLowerCase()}` ]);
                user?.homeScreen !== HomeScreen.ADMIN &&
                user?.homeScreen !== HomeScreen.VENDOR
                    ? await this._router.navigate([
                          '/',
                          `${user?.homeScreen.toLowerCase()}`,
                      ])
                    : await this._router.navigate(['/']);
                return false;
            }
            // console.log("user3: ", user, interfaces, childRoute.url.map((u) => u.path).toString());
        }
        // console.log('test');

        // Allow the access
        return true;
    }
}
