import { API_URLS } from '@aman/utils/API_URLS';
import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import * as L from "leaflet";

@Component({
  selector: 'app-leaflet-map',
  templateUrl: './leaflet-map.component.html',
  styleUrls: ['./leaflet-map.component.scss']
})

export class LeafletMapComponent implements AfterViewInit {
    width = '100wh';
    height = '100vh';
    map: any;
    icon = L.icon({
        iconUrl: '/assets/icons/map/online.svg',
        iconSize: [40, 40],
        iconAnchor: [22, 94],
        popupAnchor: [-3, -76],
    });

    ngAfterViewInit(): void {
        this.map = L.map('platformMap').setView([30.0444, 31.2357], 9);

        L.tileLayer(API_URLS.Map.platformMap, {
            // attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            attribution: '&copy; Shelter Technology'
        }).addTo(this.map);

        L.marker([30.0444, 31.2357], {
            icon: this.icon,
            title: 'Radar One: Active',
            riseOnHover: true
          }).addTo(this.map);
    }
}
