import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent {
   radioValue = 'A';
    @Input() form: FormGroup;
    @Input() class :string = 'flex-auto';
    @Input() controlName: string = '';
    @Input() label: string;
    @Input() color: string;
}
