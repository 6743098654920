import { ControlView } from '@aman/types/controlView.type';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { firstValueFrom, map } from 'rxjs';
import { UserService } from '../../../core/user/user.service';

@Component({
    selector: 'app-control-view',
    templateUrl: './control-view.component.html',
    styleUrls: ['./control-view.component.scss'],
})
export class ControlViewComponent implements OnInit {
    @Input() controlView: ControlView;
    module: string;

    constructor(
        private _httpClient: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        public userService: UserService
    ) {}

    async ngOnInit(): Promise<void> {
        this.module = await firstValueFrom(
            this.route.parent.url.pipe(
                map((url) => {
                    return url[0].path.replace('-', '');
                })
            )
        );

        if (this.controlView.isEdit) {
            this.controlView.form.addControl(
                'id',
                new FormControl(this.controlView.editId)
            );
            if (this.controlView.getRecordEndpoint)
                this.getRecord(this.controlView.editId);
        }
    }

    toggleForm(id) {
        this._httpClient
            .put(this.controlView.lockFormEndpoint.replace('{id}', id), '')
            .subscribe((res) => {
                return res
                    ? this.controlView.form.disable()
                    : this.controlView.form.enable();
            });
    }

    private addRecord() {
        // console.log('add form', this.controlView.form.value)
        return this._httpClient
            .post(this.controlView.addEndpoint, this.controlView.form.value)
            .subscribe((res) => {
                this.router.navigate([this.controlView.redirectRoute]);
            });
    }

    private getRecord(id) {
        this._httpClient
            .get(this.controlView.getRecordEndpoint.replace('{id}', id))
            .subscribe((res: any) => {
                this.controlView.form.patchValue(res);

                if (res.locked) {
                    this.controlView.form.disable();
                } else {
                    this.controlView.form.enable();
                }
                // res.locked ? this.controlView.form.disable() : null;
            });
    }

    private updateRecord(id) {
        return this._httpClient
            .put(
                this.controlView.updateEndPoint.replace('{id}', id),
                this.controlView.form.value
            )
            .subscribe((res) => {
                this.router.navigate([this.controlView.redirectRoute]);
            });
    }

    submit() {
        return this.controlView.editId
            ? this.updateRecord(this.controlView.editId)
            : this.addRecord();
    }
}
