<ng-container [formGroup]="form">

    <div [ngClass]="class" class="w-full">
      <mat-label *ngIf="addLabel" [ngClass]="labelClasses">{{ label }}</mat-label>
<br>
      <nz-date-picker
      [ngClass]="class"
      [formControlName]="controlName"
      [nzShowTime]="nzShowTime"

      [nzRanges]="showRanges ? ranges: null"
      nzFormat="yyyy-MM-dd HH:mm:ss"
      nzPlaceHolder="Select Date"
      (nzOnOpenChange)="handleStartOpenChange($event)"
    ></nz-date-picker>
   
<br>
<mat-error [ngClass]="errorClasses" *ngIf="form.get(controlName)?.hasError('required')">{{label}} {{'is required.'}}</mat-error>

    </div>


</ng-container>