export const AG_GRID_LOCALE_AR = {
    // Set Filter
    selectAll: '(اختيار الكل)',
    selectAllSearchResults: '(اختيار كل نتائج البحث)',
    searchOoo: 'بحث...',
    blanks: '(فارغة)',
    noMatches: 'لا يوجد بيانات مشابهة',

    // Number Filter & Text Filter
    filterOoo: 'فلتر...',
    equals: 'يساوي',
    notEqual: 'لا يسااوي',
    blank: 'فارغ',
    notBlank: 'غير فارغ',
    empty: 'اختر واحداً',

    // Number Filter
    lessThan: 'أقل من',
    greaterThan: 'أكبر من',
    lessThanOrEqual: 'أقل من أو يساوي',
    greaterThanOrEqual: 'أكبر من أو يساوي',
    inRange: 'في نطاق',
    inRangeStart: 'من',
    inRangeEnd: 'إلى',

    // Text Filter
    contains: 'يحتوي على',
    notContains: 'لا  يحتوي على',
    startsWith: 'يبدأ ب',
    endsWith: 'ينتهي ب',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',

    // Filter Conditions
    andCondition: 'و',
    orCondition: 'أو',

    // Filter Buttons
    applyFilter: 'موافق',
    resetFilter: 'إستعادة',
    clearFilter: 'مسح',
    cancelFilter: 'إلغاء',

    // Filter Titles
    textFilter: 'تصفية النص',
    numberFilter: 'تصفية الرقم',
    dateFilter: 'تصفية التاريخ',
    setFilter: 'تصفية المجموعة',

    // Side Bar
    columns: 'الأعمدة',
    filters: 'التصفيات',

    // columns tool panel
    pivotMode: 'الوضع  المحوري',
    groups: 'مجموعات الصفوف',
    rowGroupColumnsEmptyMessage: 'اسحب الأعمدة هنا للتجميع بقيمها',
    values: 'Values',
    valueColumnsEmptyMessage: 'Drag here to aggregate',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Drag here to set column labels',

    // Header of the Default Group Column
    group: 'مجموعة',

    // Other
    loadingOoo: 'جاري التحميل...',
    noRowsToShow: 'لا توجد بيانات ',
    enabled: 'مفعل',

    // Menu
    pinColumn: 'تثبيت العمود',
    pinLeft: 'تثبيت إلى اليسار',
    pinRight: 'تثبيت إلى اليمين',
    noPin: 'إلغاء التثبيت',
    valueAggregation: 'Value Aggregation',
    autosizeThiscolumn: 'ضبط حجم العمود تلقائياً',
    autosizeAllColumns: 'ضبط حجم كل الأعمدة تلقائيا',
    groupBy: 'تجميع بواسطة',
    ungroupBy: 'إلغاء التجميع بواسطة',
    addToValues: 'Add ${variable} to values',
    removeFromValues: 'Remove ${variable} from values',
    addToLabels: 'Add ${variable} to labels',
    removeFromLabels: 'Remove ${variable} from labels',
    resetColumns: 'إعادة ضبط الأعمدة',
    expandAll: 'فتح الكل',
    collapseAll: 'إغلاق الكل',
    copy: 'نسخ',
    ctrlC: 'Ctrl+C',
    copyWithHeaders: 'نسخ مع العناوين',
    copyWithHeaderGroups: 'نسخ مع عناوين المجموعات',
    paste: 'لصق',
    ctrlV: 'Ctrl+V',
    export: 'تصدير',
    csvExport: 'CSV تصدير',
    excelExport: 'Excel تصدير',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'مجموع',
    min: 'أقل',
    max: 'أكبر',
    none: 'لا شيء',
    count: 'عدد',
    avg: 'متوسط',
    filteredRows: 'المصفاة',
    selectedRows: 'المختارة',
    totalRows: 'مجموع السطور',
    totalAndFilteredRows: 'السطور',
    more: 'المزيد',
    to: 'إلى',
    of: 'من',
    page: 'صفحة',
    nextPage: 'الصفحة التاالية',
    lastPage: 'الصفحة الأخيرة',
    firstPage: 'الصفحة الأولى',
    previousPage: 'الصفحة اللسابقة',

    // Pivoting
    pivotColumnGroupTotals: 'مجموع',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',

    columnChart: 'Column',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Line',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogram',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Settings',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Background',
    font: 'Font',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Linked to Grid',
    chartUnlinkToolbarTooltip: 'Unlinked from Grid',
    chartDownloadToolbarTooltip: 'Download Chart',

    // ARIA
    ariaHidden: 'hidden',
    ariaVisible: 'visible',
    ariaChecked: 'checked',
    ariaUnchecked: 'unchecked',
    ariaIndeterminate:'indeterminate',
    ariaDefaultListName: 'List',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaInputEditor: 'Input Editor',
    ariaDateFilterInput: 'Date Filter Input',
    ariaFilterList: 'Filter List',
    ariaFilterInput: 'Filter Input',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterValue: 'Filter Value',
    ariaFilterFromValue: 'Filter from value',
    ariaFilterToValue: 'Filter to value',
    ariaFilteringOperator: 'Filtering Operator',
    ariaColumn: 'Column',
    ariaColumnList: 'Column List',
    ariaColumnGroup: 'Column Group',
    ariaRowSelect: 'Press SPACE to select this row',
    ariaRowDeselect: 'Press SPACE to deselect this row',
    ariaRowToggleSelection: 'Press Space to toggle row selection',
    ariaRowSelectAll: 'Press Space to toggle all rows selection',
    ariaToggleVisibility: 'Press SPACE to toggle visibility',
    ariaSearch: 'Search',
    ariaSearchFilterValues: 'Search filter values',

    ariaRowGroupDropZonePanelLabel: 'Row Groups',
    ariaValuesDropZonePanelLabel: 'القيم',
    ariaPivotDropZonePanelLabel: 'Column Labels',
    ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
    ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',

    // ARIA Labels for Dialogs
    ariaLabelColumnMenu: 'Column Menu',
    ariaLabelCellEditor: 'Cell Editor',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Select Field',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Context Menu',
    ariaLabelSubMenu: 'SubMenu',
    ariaLabelAggregationFunction: 'دالة التجميع',

    // Number Format (Status Bar, Pagination Panel)
    thousandSeparator: ',',
    decimalSeparator: '.'

}
