import { Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import { registerLocaleData } from '@angular/common';
import { ar_EG, en_US, NzI18nService } from 'ng-zorro-antd/i18n';
import ar from '@angular/common/locales/ar';
import en from '@angular/common/locales/en';
import { firstValueFrom } from 'rxjs';

@NgModule({
    exports  : [
        TranslocoModule
    ],
    providers: [
        {
            // Provide the default Transloco configuration
            provide : TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs      : [
                    {
                        id   : 'en',
                        label: 'English'
                    },
                    {
                        id   : 'ar',
                        label: 'العربية'
                    }
                ],
                defaultLang         : 'ar',
                fallbackLang        : 'en',
                reRenderOnLangChange: true,
                prodMode            : true
            })
        },
        {
            // Provide the default Transloco loader
            provide : TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader
        },
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide   : APP_INITIALIZER,
            deps      : [TranslocoService, NzI18nService],
            useFactory: (translocoService: TranslocoService, i18n: NzI18nService): any => (): Promise<Translation> => {
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(localStorage.getItem('language') || defaultLang);
                if ((localStorage.getItem('language') || defaultLang) === 'ar') {
                    const htmlElement = document.getElementsByTagName('html')[0];
                    htmlElement.setAttribute('lang', 'ar');
                    htmlElement.setAttribute('dir', 'rtl');
                    registerLocaleData(ar);
                    i18n.setLocale(ar_EG);
                } else {
                    const htmlElement = document.getElementsByTagName('html')[0];
                    htmlElement.setAttribute('lang', 'en');

                    htmlElement.setAttribute('dir', 'ltr');
                    registerLocaleData(en);
                    i18n.setLocale(en_US)
                }

                return firstValueFrom(translocoService.load(defaultLang))
            },
            multi     : true
        }
    ]
})
export class TranslocoCoreModule
{
}
