import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.scss']
})
export class CheckBoxComponent {
  @Input() form: FormGroup;
  @Input() class :string = 'flex-auto';
  @Input() controlName: string = '';
  @Input() addLabel: boolean = true;

    @Input() label: string;
    @Input() color: string;
    @Input() labelClasses: string;
    @Input() iconClass: string;


}
