<ng-container [formGroup]="form">
    <div [ngClass]="class" class="w-full">
        <nz-switch
            [ngClass]="class"
            [formControlName]="controlName"
            [nzCheckedChildren]="checkedTemplate"
            [nzUnCheckedChildren]="unCheckedTemplate"
            nzSize=""
        ></nz-switch>
        <mat-label class="font-lg ml-2 mb-2 rtl:mr-4">{{ label }}</mat-label>
        <ng-template #checkedTemplate
            ><span nz-icon nzType="check"></span
        ></ng-template>
        <ng-template #unCheckedTemplate
            ><span nz-icon nzType="close"></span
        ></ng-template>
        <!-- <mat-label class="font-lg">{{label}}</mat-label> -->
    </div>
</ng-container>
