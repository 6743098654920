import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AntTreeObject } from '../../../../@aman/types/ant-tree.type';
import { API_URLS } from '../../../../@aman/utils/API_URLS';

@Component({
    selector: 'radar-tree',
    templateUrl: './radar-tree.component.html',
    styleUrls: ['./radar-tree.component.scss'],
})
export class RadarTreeComponent implements OnInit {
    options: AntTreeObject[];

    constructor(private _httpClient: HttpClient) {}
    @Input() form: FormGroup;
    @Input() tags: number = 2;
    @Input() controlName: string;
    @Input() multiple: boolean = true;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() class: string = 'flex-auto';
    @Input() labelClasses: string = 'w-full';
    @Input() treeClass: string;

    // @Input() maxPlaceHolder:string
    ngOnInit(): void {
        this._httpClient
            .get<AntTreeObject[]>(API_URLS.TreeCategory.getTree)
            .subscribe((data) => {
                this.options = data;
            });
        this.form.get(this.controlName).valueChanges.subscribe((value) => {
            // console.log(value);
        });
    }

    onChange($event: any[]): void {
        // console.log($event);
    }
}
