import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit, OnChanges {
    @Input() form: FormGroup;
    @Input() controlName: string;
    @Input() label: string;
    @Input() color: string;
    @Input() class: string = 'flex-auto';

    constructor() {}

    ngOnInit(): void {}
    ngOnChanges(changes: SimpleChanges): void {}
}
