import { API_URLS } from '@aman/utils/API_URLS';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { AntTreeObject } from '../../../../../../@aman/types/ant-tree.type';
import { UserService } from '../../../../../core/user/user.service';
import { User } from '../../../../../../@aman/types/user.type';

@Component({
    selector: 'app-radar-filter',
    templateUrl: './radar-filter.component.html',
    styleUrls: ['./radar-filter.component.scss'],
})
export class RadarFilterComponent implements OnInit {
    id: any;
    RoadsTree: any = '';
    params!: IFilterParams;
    value;
    selecteValues: Array<string> = [];
    nodes = [];
    form: FormGroup;
    constructor(private _httpClient: HttpClient, private fb: FormBuilder) {}

    ngOnInit(): void {
        this._httpClient
            .get<AntTreeObject[]>(API_URLS.TreeCategory.getTree)
            .subscribe((data) => {
                this.nodes = data;
            });
        // this.form = this.fb.group({
        //     radarTree:[null]
        //  })

        //  this.form.valueChanges.subscribe((newvalue)=>{
        //     console.log('newValue',newvalue);
        //     this.params.filterChangedCallback();

        //        })
        // this.getRoadsTree();
    }

    // getRoadsTree() {
    //     return this._httpClient
    //         .get(API_URLS.Road.getTree)
    //         .subscribe((res: any) => {
    //             res.forEach((road) => {
    //                 if (road?.governorates?.length > 0) {

    //                     road?.governorates?.forEach((gov) => {
    //                         if (gov?.radars?.length > 0) {
    //                             const roadIndex =  this.nodes.findIndex((roadNode) => roadNode.value === road.id);
    //                             console.log("radarasss: ", gov.radars)
    //                             if (roadIndex === -1) {
    //                                 this.nodes.push({
    //                                     title: road.name,
    //                                     value: road.id,
    //                                     key: `key-${road.id}`,
    //                                     isLeaf: false,
    //                                     children: [{
    //                                         title: gov.name,
    //                                         value: gov.id,
    //                                         key: `gov-${gov.id}`,
    //                                         isLeaf: false,
    //                                         children: gov.radars.map(
    //                                             (radar, y) => ({
    //                                                 title: radar.name,
    //                                                 value: radar.id,
    //                                                 key: radar.id,
    //                                                 isLeaf: true,
    //                                             })
    //                                         ),
    //                                     }]
    //                                 });
    //                             } else {
    //                                 this.nodes[roadIndex].children.push({
    //                                     title: gov.name,
    //                                     value: gov.id,
    //                                     key: `gov-${gov.id}`,
    //                                     isLeaf: false,
    //                                     children: gov.radars.map(
    //                                         (radar, y) => ({
    //                                             title: radar.name,
    //                                             value: radar.id,
    //                                             key: radar.id,
    //                                             isLeaf: true,
    //                                         })
    //                                     ),
    //                                 })
    //                             }
    //                         }
    //                     });
    //                 }

    //             });
    //             // this.nodes = res.map((road, i) => (

    //             //     {
    //             //     title: road.name,
    //             //     value: road.id,
    //             //     key: road.id,
    //             //     children: road.governorates.map((gov, y) => ({
    //             //         title: gov.name,
    //             //         value: gov.id,
    //             //         key: gov.id,
    //             //         // isLeaf: gov?.radars.length > 0 ? true : false,
    //             //         children: gov.radars.map((radar, y) => ({
    //             //             title: radar.name,
    //             //             value: radar.id,
    //             //             key: radar.id,
    //             //         }))
    //             //     })),
    //             // }
    //             // ));
    //         });
    // }

    agInit(params: IFilterParams): void {
        this.params = params;
        console.log('params:', this.params);
    }

    // doesFilterPass(params: IDoesFilterPassParams) {
    //     console.log(params);
    //     if (this.selecteValues.length === 0) return true;

    //     for (let i = 0; i < this.selecteValues.length; i++) {
    //         if (this.selecteValues[i] === params.data.radar) return true;
    //     }
    //     return false;
    // }

    isFilterActive(): boolean {
        return true;
    }

    getModel() {
        if (!this.isFilterActive()) {
            return null;
        }
        if (this.value.length > 0) {
            {
                //     return {
                //         valueType: "Set",
                //         type: "contains",
                //         value: this.value
                //    }
                return this.value;
            }
        }
    }
    onChange(event: any) {
        console.log(this.value);
        console.log('event', event);
        this.params.filterChangedCallback();
    }
    // setModel(model: any) {
    //     this.selecteValues = model == null ? null : model.value;
    // }

    // onChange(event, nodes) {
    //     console.log("e: ", event);
    //     console.log("nodes: ", nodes);
    //     this.setSelectedValues(nodes);
    //     this.params.filterChangedCallback();
    // }

    // setSelectedValues(nodes) {
    //     if (nodes.length === 0) return (this.selecteValues = []);
    //     return nodes.forEach((node) => {
    //         if (node.checked) {
    //             if (node.children) {
    //                 this.setSelectedValues(node.children);
    //             } else if (node.isLeaf && node.checked) {
    //                 const index = this.selecteValues.indexOf(node.value);
    //                 if (index === -1) this.selecteValues.push(node.value);
    //             }
    //         } else {
    //             if (node.children) {
    //                 this.setSelectedValues(node.children);
    //             } else if (node.isLeaf && !node.checked) {
    //                 const index = this.selecteValues.indexOf(node.value);
    //                 if (index !== -1) this.selecteValues.splice(index, 1);
    //             }
    //         }
    //     });

    //     // if (nodes.length === 0) return this.selecteValues = [];
    //     // return nodes.forEach(node => {
    //     //     if (node.checked) {
    //     //         if(node.children) {this.setSelectedValues(node.children)}
    //     //         else if (node.isLeaf && node.checked) {
    //     //             const index = this.selecteValues.indexOf(node.value);
    //     //             if (index === -1) this.selecteValues.push(node.value);
    //     //         };
    //     //     }
    //     //     else {
    //     //         if(node.children) {this.setSelectedValues(node.children)}
    //     //         else if (node.isLeaf && !node.checked) {
    //     //             const index = this.selecteValues.indexOf(node.value);
    //     //             if (index > -1) this.selecteValues.splice(index, 1);
    //     //         }
    //     //     }
    //     // });
    // }
}
