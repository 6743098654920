<ng-container [formGroup]="form">
    <div [ngClass]="class" class="w-full">
        <mat-label class="font-lg font-[500]" >{{label | transloco}} <span
                *ngIf="form.get(controlName)?.errors?.required"
                class="mat-mdc-form-field-required-marker mdc-floating-label--required"></span>
        </mat-label>
        <br>
        <nz-upload [nzAction]="uploadUrl" (nzChange)="uploadFile($event)" nzPreviewIsImage
            nzListType="picture-card" [nzFileType]="'image/png,image/jpeg,image/gif,image/bmp'" class="flex"	 
            [nzPreview]="handlePreview"  
            [nzAccept]="'image/png,image/jpeg,image/gif,image/bmp'"
             [(nzFileList)]="defaultFileList">
            <a nz-button class="w-full">
                <span nz-icon nzType="upload"></span>
                {{'Upload' |transloco}}
            </a>
        </nz-upload>
        <nz-modal
        [nzVisible]="previewVisible"
        [nzContent]="modalContent"

        (nzOnCancel)="previewVisible = false"
      >
        <ng-template #modalContent>
          <img [src]="previewImage" [ngStyle]="{ width: '100%' }" />
        </ng-template>
      </nz-modal>
    </div>
</ng-container>
