import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injectable,
    Input,
    OnInit,
    ViewChild,
} from '@angular/core';
import {
    EntityType,
    EntityTypeArray,
} from '../../../@aman/enums/entity-type.enum';
import {
    FormGroup,
    FormBuilder,
    FormControl,
    Validators,
    FormArray,
} from '@angular/forms';
import {
    MatTreeFlattener,
    MatTreeFlatDataSource,
    MatTreeNestedDataSource,
} from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import {
    NzFormatEmitEvent,
    NzTreeComponent,
    NzTreeNode,
    NzTreeNodeOptions,
} from 'ng-zorro-antd/tree';
import { TranslocoService } from '@ngneat/transloco';
import { SecurityLine } from '../../../@aman/types/securityProfile.type';

@Component({
    selector: 'role-tree',
    templateUrl: './rolel-tree.component.html',
    styleUrls: ['./rolel-tree.component.scss'],
})
export class RolelTreeComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() entityTypes: EntityType[];
    @Input() parent: string = 'Parent';
    nodes: NzTreeNodeOptions[] = [];

    constructor(private translate: TranslocoService, private fb: FormBuilder) {}

    public get lines(): FormArray {
        return this.form.get('lines') as FormArray;
    }
    @ViewChild('tree', { static: false }) tree!: NzTreeComponent;

    ngOnInit() {
        let nodes = this.lines.controls.filter((control) =>
            this.entityTypes.includes(control.get('entityType').value)
        );

        this.nodes = [
            {
                title: this.translate.translate(this.parent),
                key: null,
                expanded: true,
                children: nodes.map((node, index) => {
                    return {
                        title: this.translate.translate(
                            node.get('entityType').value
                        ),
                        key: node.get('entityType').value,
                        expanded: index === 0,
                        children: [
                            {
                                title: this.translate.translate('Read'),
                                key: `${node.get('entityType').value}.canRead`,
                                isLeaf: true,
                            },
                            {
                                title: this.translate.translate('Add'),
                                key: `${
                                    node.get('entityType').value
                                }.canCreate`,
                                isLeaf: true,
                            },
                            {
                                title: this.translate.translate('Update'),
                                key: `${
                                    node.get('entityType').value
                                }.canUpdate`,
                                isLeaf: true,
                            },
                            {
                                title: this.translate.translate('Delete'),
                                key: `${
                                    node.get('entityType').value
                                }.canDelete`,
                                isLeaf: true,
                            },
                            {
                                title: this.translate.translate('Lock'),
                                key: `${node.get('entityType').value}.canLock`,
                                isLeaf: true,
                            },
                            {
                                title: this.translate.translate('Unlock'),
                                key: `${
                                    node.get('entityType').value
                                }.canUnlock`,
                                isLeaf: true,
                            },
                        ],
                    };
                }),
            },
        ];
        this.lines.valueChanges.subscribe((values: SecurityLine[]) => {
            const thisOnlyValues = values.filter((value) =>
                this.entityTypes.includes(value.entityType)
            );

            thisOnlyValues.forEach((val) => {
                let node = this.tree.getTreeNodeByKey(val.entityType);

                node?.getChildren()?.forEach((child) => {
                    switch (child.key) {
                        case `${val.entityType}.canRead`: {
                            child.setChecked(val.canRead);
                            break;
                        }
                        case `${val.entityType}.canCreate`: {
                            child.setChecked(val.canCreate);
                            break;
                        }
                        case `${val.entityType}.canUpdate`: {
                            child.setChecked(val.canUpdate);
                            break;
                        }
                        case `${val.entityType}.canDelete`: {
                            child.setChecked(val.canDelete);
                            break;
                        }
                        case `${val.entityType}.canLock`: {
                            child.setChecked(val.canLock);
                            break;
                        }
                        case `${val.entityType}.canUnlock`: {
                            child.setChecked(val.canUnlock);
                            break;
                        }
                        default: {
                            // console.log('Default', child)
                            break;
                        }
                    }
                });
                const allChecked =
                    val.canRead &&
                    val.canCreate &&
                    val.canUpdate &&
                    val.canDelete &&
                    val.canLock &&
                    val.canUnlock;
                const someChecked =
                    val.canRead ||
                    val.canCreate ||
                    val.canUpdate ||
                    val.canDelete ||
                    val.canLock ||
                    val.canUnlock;
                node.setChecked(allChecked, allChecked ? null : someChecked);
                // console.log(node,val, new Date());
            });

            let parentNode = this.tree.getTreeNodeByKey(this.parent);

            let allChecked = thisOnlyValues.reduce((acc, line) => {
                return acc && line.canCreate;
            }, false);

            // parentNode.setSyncChecked(allChecked)
            parentNode?.setChecked(allChecked);
        });
    }

    onItemChecked(event: NzFormatEmitEvent): void {
        // console.log('eveveve' + event);

        const checked = event.node.isChecked;
        const key = event.node.key;

        if (key) {
            if (key.includes('.')) {
                const keys = key.split('.');
                let group = this.lines.controls.find(
                    (group) => group.get('entityType').value === keys[0]
                );
                group?.get(keys[1]).setValue(checked);
            } else {
                let group = this.lines.controls.find(
                    (group) => group.get('entityType').value === key
                );
                group?.patchValue({
                    canRead: checked,
                    canCreate: checked,
                    canUpdate: checked,
                    canDelete: checked,
                    canLock: checked,
                    canUnlock: checked,
                });
            }
        } else {
            let nodes = this.lines.controls.filter((control) =>
                this.entityTypes.includes(control.get('entityType').value)
            );
            nodes.forEach((group) => {
                group.patchValue({
                    canRead: checked,
                    canCreate: checked,
                    canUpdate: checked,
                    canDelete: checked,
                    canLock: checked,
                    canUnlock: checked,
                });
            });
        }

        // console.log(this.lines.value);
    }
}
