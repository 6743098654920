<div class="sm:col-span-2 lg:col-span-3 dark flex flex-col flex-auto bg-card shadow rounded-2xl overflow-hidden">
    <div class="flex items-center justify-between mt-10 ml-10 mr-6 sm:mr-10">
        <div class="flex flex-col">
            <div class="mr-4 text-2xl md:text-3xl font-semibold tracking-tight leading-7">{{'Violations Count'
                |transloco}} </div>
            <div class="font-medium text-secondary">{{radar.name}}</div>
        </div>
        <div class="ml-2">
            <mat-button-toggle-group class="hidden sm:inline-flex border-none space-x-1" value="this-month"
                #visitorsYearSelector="matButtonToggleGroup">
                <mat-button-toggle class="px-1.5 rounded-full overflow-hidden border-none font-medium"
                    value="last-month">{{'Last Month' |transloco}}
                </mat-button-toggle>
                <mat-button-toggle class="px-1.5 rounded-full overflow-hidden border-none font-medium"
                    value="this-month">{{'This Month' |transloco}}
                </mat-button-toggle>
            </mat-button-toggle-group>
            <div class="sm:hidden">
                <button mat-icon-button [matMenuTriggerFor]="visitorsMenu">
                    <mat-icon [svgIcon]="'heroicons_outline:dots-vertical'"></mat-icon>
                </button>
                <mat-menu #visitorsMenu="matMenu">
                    <button mat-menu-item>{{'This Month' |transloco}}</button>
                    <button mat-menu-item>{{'Last Month' |transloco}}</button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="flex flex-col flex-auto h-80">
        <apx-chart class="flex-auto w-full h-full" [chart]="chartViolations?.chart" [colors]="chartViolations?.colors"
            [dataLabels]="chartViolations?.dataLabels" [fill]="chartViolations?.fill" [grid]="chartViolations?.grid"
            [series]="chartViolations?.series?.[visitorsYearSelector.value]" [stroke]="chartViolations?.stroke"
            [tooltip]="chartViolations?.tooltip" [xaxis]="chartViolations?.xaxis"
            [yaxis]="chartViolations?.yaxis"></apx-chart>
    </div>
</div>