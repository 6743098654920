import { Component } from '@angular/core';

@Component({
    selector: 'app-ag-delete-renderer',
    templateUrl: './ag-delete-renderer.component.html',
    styleUrls: ['./ag-delete-renderer.component.scss'],
})
export class AgDeleteRendererComponent {
    params: any;
    getLabelFunction: any;

    agInit(params: any): void {
        this.params = params;
        this.getLabelFunction = this.params.getLabelFunction;
    }

    refresh(params?: any): boolean {
        return true;
    }
    isPopup(): boolean {
        return false;
    }

    onClick($event) {
        if (this.params.onClick instanceof Function) {
            const params = {
                event: $event,
                rowData: this.params.node,
            };
            this.params.onClick(params);
        }
    }
}
