import { API_URLS } from '@aman/utils/API_URLS';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-reset-password-renderer',
  templateUrl: './reset-password-renderer.component.html',
  styleUrls: ['./reset-password-renderer.component.scss']
})
export class ResetPasswordRendererComponent {
    params: any;
    getLabelFunction: any;

    constructor(
        private _fuseConfirmationService: FuseConfirmationService,
        private _httpClient: HttpClient,
        private translate: TranslocoService,
    ) {}

    agInit(params: any): void {
        this.params = params;
        this.getLabelFunction = this.params.getLabelFunction;
    }

    refresh(params?: any): boolean {
        return true;
    }

    isPopup(): boolean {
        return false;
    }

    resetPassword(event): void
    {
        // Open the confirmation dialog
        const confirmation = this._fuseConfirmationService.open({
            title  : `${this.translate.translate("Reset Password For")} ${this.params.data.name} (${this.params.data.username})`,
            message: `${this.translate.translate("Are you sure you want to reset password for this user?")} , <span class="font-medium inline-block">${this.translate.translate("This action cannot be undone!")}</span> `,
            actions: {
                // confirm: {
                //     label: 'Reset'
                // }
                confirm: {
                    show: true,
                    label:  this.translate.translate('Reset'),
                    color: 'warn',
                },
                cancel: {
                    show: true,
                    label:  this.translate.translate('Cancel'),
                },
            }
        });

        // Subscribe to the confirmation dialog closed action
        confirmation.afterClosed().subscribe((result) => {

            // If the confirm button pressed...
            if ( result === 'confirmed' )
            {
                this._httpClient.put(API_URLS.User.resetPassword.replace('{id}', this.params.data.id), '').subscribe((res) => {
                    console.log("loooog: ", res);
                })
            }
        });
    }
}
