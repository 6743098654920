import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, of, switchMap, tap, throwError, firstValueFrom } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { API_URLS } from '@aman/utils/API_URLS';
import { OverrideService } from '@aman/utils/override.service';
import { User } from '@aman/types/user.type';
import { ReviewerBasicDataService } from 'app/modules/aman-platform/employee-dashboard/reviewer/reviewer-basic-data.service';
import { SocketService } from '@aman/utils/socket.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class AuthService {
    private _authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private override: OverrideService,
        private socketService: SocketService
        , private _snackBar: MatSnackBar
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    async signIn(credentials: { email: string; password: string }): Promise<any> {
        // Throw error, if the user is already logged in
        // if (this._authenticated) {
        //     throw new Error('User is already logged in.');

        // }

        let response = await firstValueFrom(this._httpClient.post<User>(API_URLS.Auth.login, credentials, {
            headers: this.override.AuthHeaders
        }))

        this.socketService.getNotifications(`user:${response}`).subscribe()
        if (response.securityProfile)
            this.socketService.getNotifications(`securityProfile:${response?.securityProfile?.id}`).subscribe()
        // Store the access token in the local storage
        this.accessToken = response.token;

        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        this._userService.user = response;

        // Return a new observable with the response
        return response;
    }

    /**
     * Sign in using the access token
     */
    async signInUsingToken(): Promise<any> {
        // Sign in using the token
        try {
            let user = await firstValueFrom(this._httpClient.post<User>(API_URLS.Auth.loginByToken, {
                accessToken: this.accessToken
            }))

            if (user.token) {
                this.accessToken = user.token;
            }

            if (user.id)
                this.socketService.getNotifications(`user:${user?.id}`).subscribe()

            if (user.securityProfile)
                this.socketService.getNotifications(`securityProfile:${user?.securityProfile?.id}`).subscribe()

            // Set the authenticated flag to true
            this._authenticated = true;

            // Store the user on the user service
            this._userService.user = user;

            // Return true
            return true;
        } catch (error) {
            return false;

        }
    }

    /**
     * Sign out
     */
    async signOut(): Promise<any> {
        await this._userService.user$.subscribe(user => {
            this.socketService.getNotifications(`user:${user.id}`).subscribe()
            if (user.securityProfile)
                this.socketService.getNotifications(`securityProfile:${user?.securityProfile?.id}`).subscribe()
        })

        await this._httpClient.post<any>(API_URLS.Auth.logout, null).subscribe(res => {
            console.log(res)
        });

        // this.socketService.removeListenerNotification(`user:${response.id}`)
        // removeListenerNotification
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');

        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    async check(): Promise<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return true;
        }

        // Check the access token availability
        if (!this.accessToken || this.accessToken === 'undefined' || this.accessToken === 'null') {
            return false;
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(this.accessToken)) {
            return false;
        }

        // If the access token exists and it didn't expire, sign in using it
        return this.signInUsingToken();
    }
}
